import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { lookup } from "mime-types";

import Grid from "@mui/material/Grid";
import SimulationCard from "../SimulationCard";
import DeleteModal from "../DeleteModal";
import DownloadDataModal from "../DownloadDataModal";
import SimulationAddEditModal from "../SimulationAddEditModal";
import EmptyListPlaceholder from "../EmptyListPlaceholder";
import FileUploadingIndicator from "../FileUploadingIndicator";

import {
  deleteSimulation,
  updateSimulation,
  insertSimulationPlatform,
} from "../../gateway/Simulation";
import { uploadImageToStorage, uploadFileToWebStorage } from "../../gateway/FileManagement";
import {
  Simulation,
  SimulationUpdateData,
  SimulationInsertData,
  SimulationPlatformInsertData,
  SimulationImageData,
  SimulationDeleteParams,
  Platform,
} from "../../utilities/types";
import {
  SimulationCardVariants,
  SimulationPlatformType,
  MessageVariants,
} from "../../utilities/enums";
import { blobToFile, extractFile } from "../../utilities/fileHelper";
import useIsMounted from "./../../utilities/useIsMounted";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import { MESSAGES, SIMULATION } from "../../constants";

import "./styles.scss";

type Props = {
  simulations: Simulation[];
  allSimulationsImageData: SimulationImageData[];
  isSimulationDeleted: boolean;
  handleSpecificUpdate: (
    id: string,
    simulationData: SimulationUpdateData | SimulationInsertData
  ) => void;
  handleSpecificUpdatePlatform: (id: string, platform: Platform) => void;
  handleSpecificDelete: (deletedSimulation: Simulation) => void;
  handleSpecificRestore: (restoredSimulation: Simulation) => void;
};

const SimulationCardList: React.FunctionComponent<Props> = ({
  simulations,
  allSimulationsImageData,
  isSimulationDeleted,
  handleSpecificUpdate,
  handleSpecificUpdatePlatform,
  handleSpecificDelete,
  handleSpecificRestore,
}) => {
  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedSimulation, setSelectedSimulation] = useState<Simulation | undefined>(undefined);
  const [editSimulationName, setEditSimulationName] = useState<string>("");
  const [editSimulationPackageName, setEditSimulationPackageName] = useState<string>("");
  const [editSimulationDescription, setEditSimulationDescription] = useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [uploaderText, setUploaderText] = useState<string>("");
  const inputFileImage = useRef<HTMLInputElement>(null);
  const inputFileVR = useRef<HTMLInputElement>(null);
  const inputFileWeb = useRef<HTMLInputElement>(null);

  const isMounted = useIsMounted();
  const message = useMessage();

  const handleDownloadDataModalOpen = (): void => {
    setDownloadDataModalOpen(true);
  };

  const handleDownloadDataModalClose = (): void => {
    setDownloadDataModalOpen(false);
  };

  const handleEditModalOpen = (): void => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = (): void => {
    setEditModalOpen(false);
  };

  const handleProgressUpdate = (
    bytes: number,
    lastBytes: number,
    totalSize: number,
    lastProgress: number
  ): number => {
    const newValue = (lastProgress * totalSize + bytes - lastBytes) / totalSize;
    return newValue;
  };

  const handleEditModalConfirm = async (): Promise<void> => {
    if (!selectedSimulation) return;
    try {
      const updateData: SimulationUpdateData = {
        name: editSimulationName,
        packageName: editSimulationPackageName,
        description: editSimulationDescription,
      };
      handleEditModalClose();
      await updateSimulation(selectedSimulation.id, updateData);
      handleSpecificUpdate(selectedSimulation.id, updateData);
      message(MESSAGES.SIMULATION.UPDATE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.UPDATE_ERROR,
        MESSAGES.SIMULATION.UPDATE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const handleDeleteModalOpen = (): void => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = (): void => {
    setDeleteModalOpen(false);
  };

  const handleDeleteModalConfirm = async (): Promise<void> => {
    if (!selectedSimulation) return;
    try {
      handleDeleteModalClose();
      await deleteSimulation(selectedSimulation.id);
      handleSpecificDelete(selectedSimulation);
      message(MESSAGES.SIMULATION.DELETE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.DELETE_ERROR,
        MESSAGES.SIMULATION.DELETE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const uploadImage = async (file: File, id: string): Promise<void> => {
    setIsFileUploading(true);
    try {
      const uniqueFileName: string = `${uuidv4()}.${file.name.split(".")[0]}`;
      let progress = 0;
      let lastBytes = 0;
      await uploadImageToStorage(file, uniqueFileName, id, (bytes) => {
        progress = handleProgressUpdate(bytes, lastBytes, file.size, progress);
        lastBytes = bytes;
        setProgressValue(progress);
      });
      const simulationData: SimulationUpdateData = { image: uniqueFileName };
      await updateSimulation(id, simulationData);
      handleSpecificUpdate(id, simulationData);
      message(MESSAGES.SIMULATION.IMAGE_UPLOAD_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.IMAGE_UPLOAD_ERROR,
        MESSAGES.SIMULATION.IMAGE_UPLOAD_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
    if (isMounted()) setIsFileUploading(false);
  };

  const handleUploadImage = (event: React.ChangeEvent): void => {
    if (!selectedSimulation) return;

    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files.length > 0) {
      uploadImage(target.files[0], selectedSimulation.id);
    }
    target.value = "";
  };

  const handleUploadImageClick = (): void => {
    inputFileImage?.current?.click();
  };

  const uploadFile = async (
    file: File,
    id: string,
    type: SimulationPlatformType
  ): Promise<void> => {
    setProgressValue(0);
    setUploaderText(SIMULATION.FILE_UPLOADING);
    setIsFileUploading(true);
    const isVR: boolean = type === SimulationPlatformType.VR;
    try {
      const uniqueFileName: string = `${uuidv4()}.${file.name.split(".")[0]}`;
      let progress = 0;
      let lastBytes = 0;
      await uploadImageToStorage(file, uniqueFileName, id, (bytes) => {
        progress = handleProgressUpdate(bytes, lastBytes, file.size, progress);
        lastBytes = bytes;
        setProgressValue(progress);
      });
      const simulationPlatformData: SimulationPlatformInsertData = {
        platform: type,
        filename: uniqueFileName,
      };
      const simulationPlatform = await insertSimulationPlatform(id, simulationPlatformData);
      handleSpecificUpdatePlatform(id, simulationPlatform);
      const successText: string = isVR
        ? MESSAGES.SIMULATION.VR_UPLOAD_SUCCESS
        : MESSAGES.SIMULATION.WEB_UPLOAD_SUCCESS;
      message(successText, MessageVariants.Success);
    } catch (error: unknown) {
      const errorText: string = isVR
        ? MESSAGES.SIMULATION.VR_UPLOAD_ERROR
        : MESSAGES.SIMULATION.WEB_UPLOAD_ERROR;
      const errorTextAuth: string = isVR
        ? MESSAGES.SIMULATION.VR_UPLOAD_ERROR_AUTH
        : MESSAGES.SIMULATION.WEB_UPLOAD_ERROR_AUTH;
      const messageText = checkMessageText(error as Error, errorText, errorTextAuth);
      message(messageText, MessageVariants.Error);
    }
    if (isMounted()) setIsFileUploading(false);
  };

  const uploadMultipleFiles = async (
    files: File[],
    type: SimulationPlatformType,
    simId: string,
    totalSize: number
  ): Promise<void> => {
    const isVR: boolean = type === SimulationPlatformType.VR;
    try {
      let progress = 0;
      for (const file of files) {
        let lastBytes = 0;
        const uniqueFileName = `${simId}/${file.name}`;
        // Just leave this be please
        // eslint-disable-next-line
        await uploadFileToWebStorage(file, uniqueFileName, (bytes) => {
          progress = handleProgressUpdate(bytes, lastBytes, totalSize, progress);
          lastBytes = bytes;
          setProgressValue(progress);
        });
      }
    } catch (error: unknown) {
      const errorText: string = isVR
        ? MESSAGES.SIMULATION.VR_UPLOAD_ERROR
        : MESSAGES.SIMULATION.WEB_UPLOAD_ERROR;
      const errorTextAuth: string = isVR
        ? MESSAGES.SIMULATION.VR_UPLOAD_ERROR_AUTH
        : MESSAGES.SIMULATION.WEB_UPLOAD_ERROR_AUTH;
      const messageText = checkMessageText(error as Error, errorText, errorTextAuth);

      message(messageText, MessageVariants.Error);
      return Promise.reject(messageText);
    }
  };

  const postSimulationPlatform = async (
    id: string,
    simId: string,
    type: SimulationPlatformType
  ): Promise<void> => {
    const isVR: boolean = type === SimulationPlatformType.VR;
    const simulationPlatformData: SimulationPlatformInsertData = {
      platform: type,
      filename: simId,
    };
    const simulationPlatform = await insertSimulationPlatform(id, simulationPlatformData);
    handleSpecificUpdatePlatform(id, simulationPlatform);
    const successText: string = isVR
      ? MESSAGES.SIMULATION.VR_UPLOAD_SUCCESS
      : MESSAGES.SIMULATION.WEB_UPLOAD_SUCCESS;
    message(successText, MessageVariants.Success);
  };

  const handleUploadVR = (event: React.ChangeEvent): void => {
    if (!selectedSimulation) return;

    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files.length > 0) {
      uploadFile(target.files[0], selectedSimulation.id, SimulationPlatformType.VR);
    }
    target.value = "";
  };

  const handleUploadVRClick = (): void => {
    inputFileVR?.current?.click();
  };

  const handleUploadWeb = async (event: React.ChangeEvent): Promise<void> => {
    if (!selectedSimulation) return;

    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files.length > 0) {
      const f: File = target.files[0];
      const entries = (await extractFile(f)).entries;
      const fileArray: File[] = [];
      setUploaderText(SIMULATION.FILE_EXTRACTING);
      setProgressValue(0);
      setIsFileUploading(true);
      let totalSize = 0;

      for (const fileName in entries) {
        const mime = lookup(fileName) ? (lookup(fileName) as string) : "";
        const blob = await entries[fileName].blob(mime);
        const unzippedFile = blobToFile(blob, fileName);
        totalSize += unzippedFile.size;
        fileArray.push(unzippedFile);
      }
      setUploaderText(SIMULATION.FILE_UPLOADING);
      const simID = uuidv4();
      uploadMultipleFiles(fileArray, SimulationPlatformType.Web, simID, totalSize)
        .then(() =>
          postSimulationPlatform(selectedSimulation.id, simID, SimulationPlatformType.Web)
        )
        .catch(() => {})
        .then(() => {
          if (isMounted()) setIsFileUploading(false);
        });
    }
    target.value = "";
  };

  const handleUploadWebClick = (): void => {
    inputFileWeb?.current?.click();
  };

  const handleRestore = async (): Promise<void> => {
    if (!selectedSimulation) return;
    try {
      const params: SimulationDeleteParams = { delete: false };
      await deleteSimulation(selectedSimulation.id, params);
      handleSpecificRestore(selectedSimulation);
      message(MESSAGES.SIMULATION.RESTORE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.RESTORE_ERROR,
        MESSAGES.SIMULATION.RESTORE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  useEffect(() => {
    if (!selectedSimulation) return;
    setEditSimulationName(selectedSimulation.name);
    setEditSimulationPackageName(selectedSimulation.packageName || "");
    setEditSimulationDescription(selectedSimulation.description);
  }, [selectedSimulation]);

  const EmptyList = (): React.ReactNode => (
    <EmptyListPlaceholder text={SIMULATION.EMPTY_LIST_PLACEHOLDER} />
  );

  const SimulationCards = (): React.ReactNode =>
    simulations.map((simulation: Simulation, index: number) => (
      <Grid key={simulation.id} item xs={12} lg={6} xl={6}>
        <SimulationCard
          index={index}
          simulation={simulation}
          variant={SimulationCardVariants.ShowMenu}
          image={
            allSimulationsImageData.find(
              (imageData: SimulationImageData) => imageData.id === simulation.id
            )?.link
          }
          isSimulationDeleted={isSimulationDeleted}
          handleUploadVR={handleUploadVRClick}
          handleUploadWeb={handleUploadWebClick}
          handleUploadImage={handleUploadImageClick}
          handleDownloadData={handleDownloadDataModalOpen}
          handleEditModalOpen={handleEditModalOpen}
          handleDeleteModalOpen={handleDeleteModalOpen}
          handleRestore={handleRestore}
          setSelectedSimulation={setSelectedSimulation}
        />
      </Grid>
    ));

  return (
    <Grid container spacing={2} className="SimulationCardList" data-testid="simulationCardList">
      {simulations.length < 1 ? EmptyList() : SimulationCards()}
      <DownloadDataModal
        title={selectedSimulation?.name}
        simulationId={selectedSimulation?.id}
        confirmText={SIMULATION.MODAL.DOWNLOAD.CONFIRM}
        open={downloadDataModalOpen}
        handleModalClose={handleDownloadDataModalClose}
        handleModalConfirm={handleDownloadDataModalClose}
      />
      <SimulationAddEditModal
        title={SIMULATION.MODAL.EDIT.TITLE}
        cancelText={SIMULATION.MODAL.EDIT.CANCEL}
        confirmText={SIMULATION.MODAL.EDIT.CONFIRM}
        open={editModalOpen}
        nameLabel={SIMULATION.MODAL.EDIT.NAME_LABEL}
        nameValue={editSimulationName}
        packageNameLabel={SIMULATION.MODAL.EDIT.PACKAGE_NAME_LABEL}
        packageNameValue={editSimulationPackageName}
        descriptionLabel={SIMULATION.MODAL.EDIT.DESCRIPTION_LABEL}
        descriptionValue={editSimulationDescription}
        setNameValue={setEditSimulationName}
        setPackageNameValue={setEditSimulationPackageName}
        setDescriptionValue={setEditSimulationDescription}
        handleModalClose={handleEditModalClose}
        handleModalConfirm={handleEditModalConfirm}
      />
      <DeleteModal
        title={SIMULATION.MODAL.DELETE.TITLE}
        cancelText={SIMULATION.MODAL.DELETE.CANCEL}
        confirmText={SIMULATION.MODAL.DELETE.CONFIRM}
        open={deleteModalOpen}
        contentText={SIMULATION.MODAL.DELETE.CONTENT}
        handleModalClose={handleDeleteModalClose}
        handleModalConfirm={handleDeleteModalConfirm}
      />
      <FileUploadingIndicator
        isFileUploading={isFileUploading}
        text={uploaderText}
        value={progressValue * 100}
      />
      <input
        id="fileUploaderImage"
        className="fileUploader"
        data-testid="fileUploaderImage"
        ref={inputFileImage}
        onChange={handleUploadImage}
        type="file"
        accept="image/png, image/gif, image/jpeg"
      />
      <input
        id="fileUploaderVR"
        className="fileUploader"
        data-testid="fileUploaderVR"
        ref={inputFileVR}
        onChange={handleUploadVR}
        type="file"
        accept=".apk"
      />
      <input
        id="fileUploaderWeb"
        className="fileUploader"
        data-testid="fileUploaderWeb"
        ref={inputFileWeb}
        onChange={handleUploadWeb}
        type="file"
        accept=".zip"
      />
    </Grid>
  );
};

export default SimulationCardList;
