import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";

import "./styles.scss";

type Props = {
  isFileUploading: boolean;
  text: string;
  value: number;
};

const FileUploadingIndicator: React.FunctionComponent<Props> = ({
  isFileUploading,
  text,
  value,
}) => (
  <Backdrop
    data-testid="fileUploadingIndicator"
    className="FileUploadingIndicator"
    open={isFileUploading}
  >
    <Card className="card">
      <CardContent className="content">
        <Typography className="text" gutterBottom>
          {text}
        </Typography>
        <Box className="box" sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            className="progress"
            variant={value < 1 ? "indeterminate" : "determinate"}
            value={value}
          />
          {value >= 1 ? (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(value)}%`}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  </Backdrop>
);

export default FileUploadingIndicator;
