/**
 * Decides which error message should be displayed. If the error contains a 401 string, it should be an unauthorized message.
 * If not, the default error message should be displayed.
 * @param {Error} error The error.
 * @param {string} errorText The default error message.
 * @param {string} errorTextAuth The auth error message.
 * @returns {string} The error message to be used.
 */
export const checkMessageText = (error: Error, errorText: string, errorTextAuth: string): string =>
  error.toString().includes("401") ? errorTextAuth : errorText;
