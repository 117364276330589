import axios, { AxiosResponse } from "axios";

import { WEB_SERVICE_URL } from "../../constants";
import { AuthParams } from "../../utilities/types";
import { isResponseValid } from "../../utilities/gatewayHelper";

const getAuthParams = async (): Promise<AuthParams> =>
  axios
    .get(`${WEB_SERVICE_URL}/auth`)
    .then((response: AxiosResponse) => {
      const responseParams = isResponseValid(response) as AuthParams;
      return responseParams;
    })
    .catch((error: Error) => {
      throw error;
    });

export default getAuthParams;
