import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";

const createInstance = (msalConfig: Configuration): PublicClientApplication => {
  const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;

      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};

export default createInstance;
