import React from "react";

import Typography from "@mui/material/Typography";
import Modal from "../Modal";

type Props = {
  title: string;
  cancelText: string;
  confirmText: string;
  contentText: string;
  open: boolean;
  handleModalClose: () => void;
  handleModalConfirm: () => void;
};

const DeleteModal: React.FunctionComponent<Props> = ({
  title,
  cancelText,
  confirmText,
  contentText,
  open,
  handleModalClose,
  handleModalConfirm,
}) => (
  <Modal
    title={title}
    cancelText={cancelText}
    confirmText={confirmText}
    open={open}
    danger
    big={false}
    hideCancel={false}
    handleModalClose={handleModalClose}
    handleModalConfirm={handleModalConfirm}
  >
    <Typography mt={2} mb={2} component="div" variant="h6">
      {contentText}
    </Typography>
  </Modal>
);

export default DeleteModal;
