import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import WebPasswordCard from "../WebPasswordCard";
import EmptyListPlaceholder from "../EmptyListPlaceholder";
import DeleteModal from "../DeleteModal";
import WebPasswordAddEditModal from "../WebPasswordAddEditModal";

import { updateWebPassword, deleteWebPassword } from "../../gateway/WebPassword";
import { WebPassword, WebPasswordUpdateData } from "../../utilities/types";
import { MessageVariants } from "../../utilities/enums";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import { MESSAGES, WEB_PASSWORD } from "../../constants";

import "./styles.scss";

type Props = {
  webPasswords: WebPassword[];
  handleSpecificDelete: (id: string) => void;
  handleSpecificUpdate: (id: string, webPasswordData: WebPasswordUpdateData) => void;
};

const WebPasswordCardList: React.FunctionComponent<Props> = ({
  webPasswords,
  handleSpecificDelete,
  handleSpecificUpdate,
}) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedWebPassword, setSelectedWebPassword] = useState<WebPassword | undefined>(
    undefined
  );
  const [editUserType, setEditUserType] = useState<string>("");
  const [editPassword, setEditPassword] = useState<string>("");

  const message = useMessage();

  const handleEditModalOpen = (): void => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = (): void => {
    setEditModalOpen(false);
  };

  const handleEditModalConfirm = async (): Promise<void> => {
    if (!selectedWebPassword) return;
    try {
      const updateData: WebPasswordUpdateData = {
        userType: editUserType,
        password: editPassword,
      };
      handleEditModalClose();
      await updateWebPassword(selectedWebPassword.id, updateData);
      handleSpecificUpdate(selectedWebPassword.id, updateData);
      message(MESSAGES.WEB_PASSWORD.UPDATE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.WEB_PASSWORD.UPDATE_ERROR,
        MESSAGES.WEB_PASSWORD.UPDATE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const handleDeleteModalOpen = (): void => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = (): void => {
    setDeleteModalOpen(false);
  };

  const handleDeleteModalConfirm = async (): Promise<void> => {
    if (!selectedWebPassword) return;
    try {
      handleDeleteModalClose();
      await deleteWebPassword(selectedWebPassword.id);
      handleSpecificDelete(selectedWebPassword.id);
      message(MESSAGES.WEB_PASSWORD.DELETE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.WEB_PASSWORD.DELETE_ERROR,
        MESSAGES.WEB_PASSWORD.DELETE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  useEffect(() => {
    if (!selectedWebPassword) return;
    setEditUserType(selectedWebPassword.userType);
    setEditPassword(selectedWebPassword.password);
  }, [selectedWebPassword]);

  const EmptyList = (): React.ReactNode => (
    <EmptyListPlaceholder text={WEB_PASSWORD.EMPTY_LIST_PLACEHOLDER} />
  );

  const WebPasswordCards = (): React.ReactNode =>
    webPasswords.map((webPassword: WebPassword, index: number) => (
      <Grid key={webPassword.id} item xs={12} md={6} xl={3}>
        <WebPasswordCard
          index={index}
          webPassword={webPassword}
          handleEditModalOpen={handleEditModalOpen}
          handleDeleteModalOpen={handleDeleteModalOpen}
          setSelectedWebPassword={setSelectedWebPassword}
        />
      </Grid>
    ));

  return (
    <Grid container spacing={2} className="WebPasswordCardList" data-testid="webPasswordCardList">
      {webPasswords.length < 1 ? EmptyList() : WebPasswordCards()}
      <WebPasswordAddEditModal
        title={WEB_PASSWORD.MODAL.EDIT.TITLE}
        cancelText={WEB_PASSWORD.MODAL.EDIT.CANCEL}
        confirmText={WEB_PASSWORD.MODAL.EDIT.CONFIRM}
        open={editModalOpen}
        userTypeLabel={WEB_PASSWORD.MODAL.EDIT.USER_TYPE_LABEL}
        userTypeValue={editUserType}
        passwordLabel={WEB_PASSWORD.MODAL.EDIT.PASSWORD_LABEL}
        passwordValue={editPassword}
        setUserTypeValue={setEditUserType}
        setPasswordValue={setEditPassword}
        handleModalClose={handleEditModalClose}
        handleModalConfirm={handleEditModalConfirm}
      />
      <DeleteModal
        title={WEB_PASSWORD.MODAL.DELETE.TITLE}
        cancelText={WEB_PASSWORD.MODAL.DELETE.CANCEL}
        confirmText={WEB_PASSWORD.MODAL.DELETE.CONFIRM}
        open={deleteModalOpen}
        contentText={WEB_PASSWORD.MODAL.DELETE.CONTENT}
        handleModalClose={handleDeleteModalClose}
        handleModalConfirm={handleDeleteModalConfirm}
      />
    </Grid>
  );
};

export default WebPasswordCardList;
