import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faCopy } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import useMessage from "./../../utilities/useMessage";
import { WebPassword } from "../../utilities/types";
import { MessageVariants } from "../../utilities/enums";
import { MESSAGES, WEB_PASSWORD } from "../../constants";

import "./styles.scss";

type Props = {
  index: number;
  webPassword: WebPassword;
  handleEditModalOpen: () => void;
  handleDeleteModalOpen: () => void;
  setSelectedWebPassword: (webPassword: WebPassword) => void;
};

const WebPasswordCard: React.FunctionComponent<Props> = ({
  index,
  webPassword,
  handleEditModalOpen,
  handleDeleteModalOpen,
  setSelectedWebPassword,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const message = useMessage();

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setSelectedWebPassword(webPassword);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleEditOnClick = (): void => {
    handleEditModalOpen();
    handleClose();
  };

  const handleDeleteOnClick = (): void => {
    handleDeleteModalOpen();
    handleClose();
  };

  const handleClipboardOnClick = (): void => {
    navigator.clipboard.writeText(webPassword.password);
    message(MESSAGES.WEB_PASSWORD.COPY_TO_CLIPBOARD, MessageVariants.Success);
  };

  const HeaderTitle = (): React.ReactNode => {
    return (
      <div className="headerTitle">
        <Typography
          component="div"
          variant="h5"
          className="text"
          noWrap
          title={webPassword.userType}
        >
          {webPassword.userType}
        </Typography>
      </div>
    );
  };

  const HeaderActionButton = (): React.ReactNode => {
    return (
      <IconButton
        data-testid="webPasswordMenuButton"
        id={`webPasswordMenuButton${index}`}
        aria-controls={`webPasswordMenu${index}`}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleMenu}
      >
        <FontAwesomeIcon icon={faEllipsisV} size="lg" />
      </IconButton>
    );
  };

  return (
    <Fade in>
      <Card raised className="WebPasswordCard" data-testid="webPasswordCard">
        <Grid container>
          <Grid item xs={12}>
            <CardHeader
              data-testid="cardHeader"
              className="cardHeader"
              action={HeaderActionButton()}
              title={HeaderTitle()}
            />
            <CardContent className="cardContent" data-testid="cardContent">
              <TextField
                autoFocus
                margin="dense"
                id="password"
                type="text"
                fullWidth
                variant="filled"
                disabled
                value={webPassword.password}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClipboardOnClick}
                        className="copyIcon"
                        size="large"
                      >
                        <FontAwesomeIcon icon={faCopy} size="xs" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </Grid>
        </Grid>
        <Menu
          data-testid="webPasswordMenu"
          id={`webPasswordMenu${index}`}
          MenuListProps={{ "aria-labelledby": `webPasswordMenuButton${index}` }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleEditOnClick}>{WEB_PASSWORD.CARD.OPTIONS.EDIT}</MenuItem>
          <MenuItem onClick={handleDeleteOnClick}>{WEB_PASSWORD.CARD.OPTIONS.DELETE}</MenuItem>
        </Menu>
      </Card>
    </Fade>
  );
};

export default WebPasswordCard;
