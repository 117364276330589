import React from "react";
import { useLocation } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CustomListItem from "../CustomListItem";
import SideMenuBottomLogos from "../SideMenuBottomLogos";
import SideMenuBottomVersion from "../SideMenuBottomVersion";

import { RouteData } from "./../../utilities/types";

import "./styles.scss";

type Props = {
  items: RouteData[];
};

const SideMenu: React.FunctionComponent<Props> = ({ items }) => {
  const location = useLocation();

  return (
    <Drawer variant="permanent" className="SideMenu">
      <div className="container">
        <List disablePadding>
          {items.map((item: RouteData) => (
            <CustomListItem
              key={item.name}
              text={item.name}
              to={item.linkPath}
              selected={item.routePaths.includes(location.pathname)}
              icon={item.icon}
            />
          ))}
        </List>
        <List disablePadding>
          <ListItem disablePadding disableGutters component="div">
            <SideMenuBottomLogos />
          </ListItem>
          <ListItem disablePadding disableGutters component="div">
            <SideMenuBottomVersion />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SideMenu;
