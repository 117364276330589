import { unzip, ZipInfo } from "unzipit";

const blobToFile = (theBlob: Blob, fileName: string): File => {
  const f: File = new File([theBlob], fileName, { type: theBlob.type });
  return f;
};

const extractFile = async (f: File): Promise<ZipInfo> => {
  const tmpPath = URL.createObjectURL(f);
  const zinfo = await unzip(tmpPath);
  return zinfo;
};

export { blobToFile, extractFile };
