import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Modal from "../Modal";

import { handleInputOnChange } from "../../utilities/inputHelper";

type Props = {
  title: string;
  cancelText: string;
  confirmText: string;
  open: boolean;
  userTypeLabel: string;
  userTypeValue: string;
  passwordLabel: string;
  passwordValue: string;
  setUserTypeValue: (value: string) => void;
  setPasswordValue: (value: string) => void;
  handleModalClose: () => void;
  handleModalConfirm: () => void;
};

const WebPasswordAddEditModal: React.FunctionComponent<Props> = ({
  title,
  cancelText,
  confirmText,
  open,
  userTypeLabel,
  userTypeValue,
  passwordLabel,
  passwordValue,
  setUserTypeValue,
  setPasswordValue,
  handleModalClose,
  handleModalConfirm,
}) => {
  const [localUserType, setLocalUserType] = useState<string>("");
  const [localPassword, setLocalPassword] = useState<string>("");

  useEffect(() => {
    if (!open) return;
    setLocalUserType(userTypeValue);
    setLocalPassword(passwordValue);
  }, [open, userTypeValue, passwordValue]);

  return (
    <Modal
      title={title}
      cancelText={cancelText}
      confirmText={confirmText}
      open={open}
      danger={false}
      big={false}
      hideCancel={false}
      confirmDisabled={localUserType.length === 0 || localPassword.length === 0}
      handleModalClose={handleModalClose}
      handleModalConfirm={handleModalConfirm}
    >
      <TextField
        autoFocus
        margin="dense"
        id="userType"
        label={userTypeLabel}
        type="text"
        fullWidth
        variant="filled"
        value={localUserType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputOnChange(event, setLocalUserType)
        }
        onBlur={() => setUserTypeValue(localUserType)}
      />
      <TextField
        margin="dense"
        id="password"
        label={passwordLabel}
        type="text"
        fullWidth
        variant="filled"
        value={localPassword}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputOnChange(event, setLocalPassword)
        }
        onBlur={() => setPasswordValue(localPassword)}
      />
    </Modal>
  );
};

export default WebPasswordAddEditModal;
