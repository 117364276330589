import React from "react";

import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

import "./styles.scss";

type Props = {
  page: number;
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const PaginationSection: React.FunctionComponent<Props> = ({ page, count, onChange }) => (
  <Grid
    container
    mt={3}
    direction="row"
    alignContent="center"
    justifyContent="center"
    data-testid="paginationSection"
  >
    <Pagination page={page} count={count} size="large" onChange={onChange} />
  </Grid>
);

export default PaginationSection;
