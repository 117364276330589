import React, { useEffect, useState, useCallback } from "react";

import Container from "@mui/material/Container";
import WebPasswordCardList from "../../components/WebPasswordCardList";
import PaginationSection from "../../components/PaginationSection";
import FloatingActionButton from "../../components/FloatingActionButton";
import InitialSection from "../../components/InitialSection";
import WebPasswordAddEditModal from "../../components/WebPasswordAddEditModal";
import PageLoader from "../../components/PageLoader";

import { getAllWebPasswords, insertWebPassword } from "../../gateway/WebPassword";
import { WebPassword, WebPasswordInsertData, WebPasswordUpdateData } from "./../../utilities/types";
import { MessageVariants } from "./../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import {
  getPagesCount,
  itemsToRender,
  filterWebPasswordsPerUserType,
} from "./../../utilities/pagination";
import { MESSAGES, WEB_PASSWORD, WEB_PASSWORDS_PER_PAGE } from "./../../constants";

const WebPasswords: React.FunctionComponent = () => {
  const [webPasswords, setWebPasswords] = useState<WebPassword[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [addWebPasswordUserType, setAddWebPasswordUserType] = useState<string>("");
  const [addWebPasswordPassword, setAddWebPasswordPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const isMounted = useIsMounted();
  const message = useMessage();

  /**
   * Web passwords are fetched
   */
  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const allWebPasswords = await getAllWebPasswords();
      allWebPasswords.sort((a: WebPassword, b: WebPassword) =>
        a.userType.localeCompare(b.userType)
      );
      if (isMounted()) setWebPasswords(allWebPasswords);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.WEB_PASSWORD.FETCH_ERROR,
        MESSAGES.WEB_PASSWORD.FETCH_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
    if (isMounted()) setLoading(false);
  }, [isMounted, message]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredWebPasswords = !filter
    ? webPasswords
    : filterWebPasswordsPerUserType(webPasswords, filter);

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilter(event.target.value);
  };

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleAddModalOpen = (): void => {
    setModalOpen(true);
  };

  const handleAddModalClose = (): void => {
    setModalOpen(false);
    clearAddModalValues();
  };

  const handleAddModalConfirm = async (): Promise<void> => {
    try {
      const insertData: WebPasswordInsertData = {
        userType: addWebPasswordUserType,
        password: addWebPasswordPassword,
      };
      handleAddModalClose();
      const newSimulation: WebPassword = await insertWebPassword(insertData);
      handleSpecificInsertion(newSimulation);
      message(MESSAGES.WEB_PASSWORD.CREATE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.WEB_PASSWORD.CREATE_ERROR,
        MESSAGES.WEB_PASSWORD.CREATE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const clearAddModalValues = (): void => {
    setAddWebPasswordUserType("");
    setAddWebPasswordPassword("");
  };

  const handleSpecificInsertion = useCallback(
    (newWebPassword: WebPassword): void => {
      const newWebPasswords: WebPassword[] = [...webPasswords];
      newWebPasswords.unshift(newWebPassword);
      newWebPasswords.sort((a: WebPassword, b: WebPassword) =>
        a.userType.localeCompare(b.userType)
      );
      if (isMounted()) setWebPasswords(newWebPasswords);
    },
    [webPasswords, isMounted]
  );

  const handleSpecificDelete = useCallback(
    (id: string): void => {
      const newWebPasswords = webPasswords.filter(
        (webPassword: WebPassword) => webPassword.id !== id
      );
      if (isMounted()) setWebPasswords(newWebPasswords);
    },
    [webPasswords, isMounted]
  );

  const handleSpecificUpdate = useCallback(
    (id: string, webPasswordData: WebPasswordUpdateData): void => {
      const newWebPasswords: WebPassword[] = [...webPasswords];
      const index: number = webPasswords.findIndex(
        (webPassword: WebPassword) => webPassword.id === id
      );
      newWebPasswords[index] = { ...newWebPasswords[index], ...webPasswordData };
      newWebPasswords.sort((a: WebPassword, b: WebPassword) =>
        a.userType.localeCompare(b.userType)
      );
      if (isMounted()) setWebPasswords(newWebPasswords);
    },
    [webPasswords, isMounted]
  );

  const Loading = (): React.ReactNode => <PageLoader />;

  const CardList = (): React.ReactNode => (
    <WebPasswordCardList
      webPasswords={
        itemsToRender(filteredWebPasswords, page, WEB_PASSWORDS_PER_PAGE) as WebPassword[]
      }
      handleSpecificDelete={handleSpecificDelete}
      handleSpecificUpdate={handleSpecificUpdate}
    />
  );

  return (
    <div data-testid="webPasswordsContent">
      <Container maxWidth="xl" disableGutters>
        <InitialSection
          pageTitle={WEB_PASSWORD.PAGE_TITLE}
          displayFilter
          onFilterChange={onFilterChange}
          displayModeSelector={false}
        />
        {loading ? Loading() : CardList()}
        <PaginationSection
          page={page}
          count={getPagesCount(filteredWebPasswords, WEB_PASSWORDS_PER_PAGE)}
          onChange={onPaginationChange}
        />
      </Container>
      <FloatingActionButton onClick={handleAddModalOpen} />
      <WebPasswordAddEditModal
        title={WEB_PASSWORD.MODAL.ADD.TITLE}
        cancelText={WEB_PASSWORD.MODAL.ADD.CANCEL}
        confirmText={WEB_PASSWORD.MODAL.ADD.CONFIRM}
        open={modalOpen}
        userTypeLabel={WEB_PASSWORD.MODAL.ADD.USER_TYPE_LABEL}
        userTypeValue={addWebPasswordUserType}
        passwordLabel={WEB_PASSWORD.MODAL.ADD.PASSWORD_LABEL}
        passwordValue={addWebPasswordPassword}
        setUserTypeValue={setAddWebPasswordUserType}
        setPasswordValue={setAddWebPasswordPassword}
        handleModalClose={handleAddModalClose}
        handleModalConfirm={handleAddModalConfirm}
      />
    </div>
  );
};

export default WebPasswords;
