import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Fab from "@mui/material/Fab";

import "./styles.scss";

type Props = {
  onClick: () => void;
};

const FloatingActionButton: React.FunctionComponent<Props> = ({ onClick }) => (
  <div className="FloatingActionButton" data-testid="floatingActionButton">
    <Fab className="element" aria-label="add" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} size="2x" />
    </Fab>
  </div>
);

export default FloatingActionButton;
