import axios, { AxiosResponse } from "axios";

import { WEB_SERVICE_URL } from "../../constants";
import { WebPassword, WebPasswordInsertData, WebPasswordUpdateData } from "../../utilities/types";
import { isResponseValid } from "../../utilities/gatewayHelper";

const getAllWebPasswords = async (): Promise<WebPassword[]> =>
  axios
    .get(`${WEB_SERVICE_URL}/web-passwords`)
    .then((response: AxiosResponse) => {
      const webPasswords: WebPassword[] = isResponseValid(response) as WebPassword[];
      return webPasswords;
    })
    .catch((error: Error) => {
      throw error;
    });

const updateWebPassword = async (id: string, data: WebPasswordUpdateData): Promise<void> =>
  axios
    .put(`${WEB_SERVICE_URL}/web-passwords/${id}`, data)
    .then((response: AxiosResponse) => {
      isResponseValid(response);
    })
    .catch((error: Error) => {
      throw error;
    });

const insertWebPassword = async (data: WebPasswordInsertData): Promise<WebPassword> =>
  axios
    .post(`${WEB_SERVICE_URL}/web-passwords`, data)
    .then((response: AxiosResponse) => {
      const webPassword: WebPassword = isResponseValid(response) as WebPassword;
      return webPassword;
    })
    .catch((error: Error) => {
      throw error;
    });

const deleteWebPassword = async (id: string): Promise<void> =>
  axios
    .delete(`${WEB_SERVICE_URL}/web-passwords/${id}`)
    .then((response: AxiosResponse) => {
      isResponseValid(response);
    })
    .catch((error: Error) => {
      throw error;
    });

export { getAllWebPasswords, updateWebPassword, insertWebPassword, deleteWebPassword };
