import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Modal from "../Modal";

import { handleInputOnChange } from "../../utilities/inputHelper";

type Props = {
  title: string;
  cancelText: string;
  confirmText: string;
  open: boolean;
  nameLabel: string;
  nameValue: string;
  packageNameLabel: string;
  packageNameValue: string;
  descriptionLabel: string;
  descriptionValue: string;
  setNameValue: (value: string) => void;
  setPackageNameValue: (value: string) => void;
  setDescriptionValue: (value: string) => void;
  handleModalClose: () => void;
  handleModalConfirm: () => void;
};

const SimulationAddEditModal: React.FunctionComponent<Props> = ({
  title,
  cancelText,
  confirmText,
  open,
  nameLabel,
  nameValue,
  packageNameLabel,
  packageNameValue,
  descriptionLabel,
  descriptionValue,
  setNameValue,
  setPackageNameValue,
  setDescriptionValue,
  handleModalClose,
  handleModalConfirm,
}) => {
  const [localName, setLocalName] = useState<string>("");
  const [localPackageName, setLocalPackageName] = useState<string>("");
  const [localDescription, setLocalDescription] = useState<string>("");

  useEffect(() => {
    if (!open) return;
    setLocalName(nameValue);
    setLocalPackageName(packageNameValue);
    setLocalDescription(descriptionValue);
  }, [open, nameValue, packageNameValue, descriptionValue]);

  return (
    <Modal
      title={title}
      cancelText={cancelText}
      confirmText={confirmText}
      open={open}
      danger={false}
      big={false}
      hideCancel={false}
      confirmDisabled={localName.length === 0}
      handleModalClose={handleModalClose}
      handleModalConfirm={handleModalConfirm}
    >
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label={nameLabel}
        type="text"
        fullWidth
        variant="filled"
        value={localName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputOnChange(event, setLocalName)
        }
        onBlur={() => setNameValue(localName)}
      />
      <TextField
        margin="dense"
        id="packageName"
        label={packageNameLabel}
        type="text"
        fullWidth
        variant="filled"
        value={localPackageName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputOnChange(event, setLocalPackageName)
        }
        onBlur={() => setPackageNameValue(localPackageName)}
      />
      <TextField
        margin="dense"
        id="description"
        label={descriptionLabel}
        type="text"
        fullWidth
        variant="filled"
        multiline
        minRows={5}
        maxRows={5}
        size="small"
        InputProps={{ classes: { input: "customScrollbar" } }}
        value={localDescription}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputOnChange(event, setLocalDescription)
        }
        onBlur={() => setDescriptionValue(localDescription)}
      />
    </Modal>
  );
};

export default SimulationAddEditModal;
