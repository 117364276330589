import { useCallback } from "react";
import { useSnackbar } from "notistack";

import { MessageVariants } from "../../utilities/enums";

/**
 * Helper hook to avoid unnecessary duplicate code related to snackbars.
 * @returns {((message: string, type: MessageVariants) => void)} A method that receives the message configuration, and displays a snackbar.
 */
const useMessage = (): ((message: string, type: MessageVariants) => void) => {
  const { enqueueSnackbar } = useSnackbar();

  const message = useCallback(
    (message: string, type: MessageVariants): void => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    },
    [enqueueSnackbar]
  );

  return message;
};

export default useMessage;
