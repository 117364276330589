import axios, { AxiosResponse } from "axios";

import { WEB_SERVICE_URL } from "../../constants";
import { Device, Simulation, SimulationIntoLibraryData } from "../../utilities/types";
import { isResponseValid } from "../../utilities/gatewayHelper";

const getAllDevices = async (): Promise<Device[]> =>
  axios
    .get(`${WEB_SERVICE_URL}/devices`)
    .then((response: AxiosResponse) => {
      const devices: Device[] = isResponseValid(response) as Device[];
      return devices;
    })
    .catch((error: Error) => {
      throw error;
    });

const deleteDevice = async (id: string): Promise<void> =>
  axios
    .delete(`${WEB_SERVICE_URL}/devices/${id}`)
    .then((response: AxiosResponse) => {
      isResponseValid(response);
    })
    .catch((error: Error) => {
      throw error;
    });

const insertIntoLibrary = async (
  deviceId: string,
  data: SimulationIntoLibraryData
): Promise<Simulation> =>
  axios
    .post(`${WEB_SERVICE_URL}/devices/${deviceId}/library`, data)
    .then((response: AxiosResponse) => {
      const simulation: Simulation = isResponseValid(response) as Simulation;
      return simulation;
    })
    .catch((error: Error) => {
      throw error;
    });

const deleteFromLibrary = async (deviceId: string, simulationId: string): Promise<void> =>
  axios
    .delete(`${WEB_SERVICE_URL}/devices/${deviceId}/library/${simulationId}`)
    .then((response: AxiosResponse) => {
      isResponseValid(response);
    })
    .catch((error: Error) => {
      throw error;
    });

export { getAllDevices, deleteDevice, insertIntoLibrary, deleteFromLibrary };
