import { AnonymousCredential, newPipeline, BlobServiceClient } from "@azure/storage-blob";
import { StorageParams } from "../../utilities/types";

const downloadFileFromStorage = async (
  params: StorageParams,
  blobFileName: string
): Promise<Blob> => {
  const parsedURL = params.url || `https://${params.accountName}.blob.core.windows.net/`;
  const anonymousCredential = new AnonymousCredential();
  const pipeline = newPipeline(anonymousCredential, { retryOptions: { maxTries: 4 } });
  const blobServiceClient = new BlobServiceClient(`${parsedURL}${params.sasKey}`, pipeline);
  const containerClient = blobServiceClient.getContainerClient(params.containerName);

  const blockBlobClient = containerClient.getBlobClient(blobFileName).getBlockBlobClient();
  return blockBlobClient
    .download()
    .then((response) => {
      if (response._response.status !== 200) throw new Error("The service answered with an error");
      return response.blobBody;
    })
    .catch((error) => error);
};

const uploadFileToStorage = async (
  params: StorageParams,
  file: File,
  blobFileName: string,
  progressCallBack: (bytes: number) => void
): Promise<void> => {
  const parsedURL = params.url || `https://${params.accountName}.blob.core.windows.net/`;
  const anonymousCredential = new AnonymousCredential();
  const pipeline = newPipeline(anonymousCredential, { retryOptions: { maxTries: 4 } });
  const blobServiceClient = new BlobServiceClient(`${parsedURL}${params.sasKey}`, pipeline);
  const containerClient = blobServiceClient.getContainerClient(params.containerName);

  const blockBlobClient = containerClient.getBlobClient(blobFileName).getBlockBlobClient();
  await blockBlobClient
    .uploadData(file, {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
      onProgress: (e) => {
        progressCallBack(e.loadedBytes);
      },
    })
    .then((response) => {
      if (response._response.status !== 201) throw new Error("The service answered with an error");
    })
    .catch((error) => error);
};

export { downloadFileFromStorage, uploadFileToStorage };
