import React from "react";

import { VERSION_TEXT } from "./../../constants";

import "./styles.scss";

const SideMenuBottomVersion: React.FunctionComponent = () => (
  <div className="SideMenuBottomVersion">
    <div className="text">{VERSION_TEXT}</div>
  </div>
);

export default SideMenuBottomVersion;
