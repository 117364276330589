import React from "react";

/**
 * Method to handle the onChange for an input. It receives the event, and the set method to be used.
 * @param {React.ChangeEvent<HTMLInputElement>} event The input change event.
 * @param {(value: string) => void} setValue The function that should be used to update the value with the event target value (usually, the set function of a React state variable).
 * @returns {void} Nothing is returned.
 */
const handleInputOnChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: (value: string) => void
): void => {
  setValue(event.target.value);
};

/**
 * Method to handle the onChange for a checkbox input. It receives the event, and the set method to be used.
 * @param {React.ChangeEvent<HTMLInputElement>} event The input change event.
 * @param {(value: boolean) => void} setValue The function that should be used to update the value with the event target value (usually, the set function of a React state variable).
 * @returns {void} Nothing is returned.
 */
const handleCheckedInputOnChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: (value: boolean) => void
): void => {
  setValue(event.target.checked);
};

export { handleInputOnChange, handleCheckedInputOnChange };
