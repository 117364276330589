import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faGripLinesVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { SimulationDisplay } from "./../../utilities/enums";
import { INITIAL_SECTION } from "./../../constants";

import "./styles.scss";

type Props = {
  pageTitle: string;
  displayFilter: boolean;
  displayModeSelector: boolean;
  currentMode?: SimulationDisplay;
  onFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onNonDeletedModeClick?: () => void;
  onDeletedModeClick?: () => void;
};

const InitialSection: React.FunctionComponent<Props> = ({
  pageTitle,
  displayFilter,
  displayModeSelector,
  currentMode,
  onFilterChange,
  onNonDeletedModeClick,
  onDeletedModeClick,
}) => (
  <div className="InitialSection" data-testid="initialSection">
    <Typography component="div" variant="h5">
      {pageTitle}
    </Typography>
    <div className="containerInteractionElements">
      {displayModeSelector && (
        <div data-testid="displayModeSelector" className="modeSelector">
          <Tooltip
            classes={{ tooltip: "customTooltipDisplay" }}
            placement="bottom"
            title={INITIAL_SECTION.ACTIVE_SIMULATIONS}
          >
            <IconButton
              disableFocusRipple
              disableRipple
              data-testid="nonDeletedSimulationsButton"
              className={`nonDeletedSimulationsButton${
                currentMode === SimulationDisplay.NonDeleted ? " selected" : ""
              }`}
              onClick={onNonDeletedModeClick}
            >
              <FontAwesomeIcon icon={faFolderOpen} size="lg" />
            </IconButton>
          </Tooltip>
          <IconButton disabled>
            <FontAwesomeIcon icon={faGripLinesVertical} size="lg" />
          </IconButton>
          <Tooltip
            classes={{ tooltip: "customTooltipDisplay" }}
            placement="bottom"
            title={INITIAL_SECTION.DELETED_SIMULATIONS}
          >
            <IconButton
              disableFocusRipple
              disableRipple
              data-testid="deletedSimulationsButton"
              className={`deletedSimulationsButton${
                currentMode === SimulationDisplay.Deleted ? " selected" : ""
              }`}
              onClick={onDeletedModeClick}
            >
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {displayFilter && (
        <TextField
          data-testid="displayFilter"
          label="Search"
          id="search"
          variant="filled"
          onChange={onFilterChange}
        />
      )}
    </div>
  </div>
);

export default InitialSection;
