import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import AuthProvider from "./auth/AuthProvider";
import AppAuthWrapper from "./containers/AppAuthWrapper";

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "Helvetica", "Arial", "Verdana", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1600,
      xl: 1750,
    },
  },
});

const Application: React.FunctionComponent = () => (
  <AuthProvider>
    <SnackbarProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <AppAuthWrapper />
        </ThemeProvider>
      </Router>
    </SnackbarProvider>
  </AuthProvider>
);

export default Application;
