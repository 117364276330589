import axios, { AxiosResponse } from "axios";

import { WEB_SERVICE_URL } from "../../constants";
import { StorageParams, FileStorage } from "../../utilities/types";
import { isResponseValid } from "../../utilities/gatewayHelper";
import { downloadFileFromStorage, uploadFileToStorage } from "../../gateway/AzureStorage";

const storage = (): FileStorage => {
  const storage: FileStorage = {
    download: downloadFileFromStorage,
    upload: uploadFileToStorage,
  };
  return storage;
};

const getSimulationsStorageParams = async (simulationId: string): Promise<StorageParams> =>
  axios
    .get(`${WEB_SERVICE_URL}/simulations/${simulationId}/file-management`)
    .then((response: AxiosResponse) => {
      const responseParams = isResponseValid(response) as StorageParams;
      return responseParams;
    })
    .catch((error: Error) => {
      throw error;
    });

const getWebStorageParams = async (): Promise<StorageParams> =>
  axios
    .get(`${WEB_SERVICE_URL}/simulations/hosting-management`)
    .then((response: AxiosResponse) => {
      const responseParams = isResponseValid(response) as StorageParams;
      return responseParams;
    })
    .catch((error: Error) => {
      throw error;
    });

const uploadImageToStorage = async (
  file: File,
  fileName: string,
  simulationId: string,
  progressCallBack: (bytes: number) => void
): Promise<void> =>
  getSimulationsStorageParams(simulationId)
    .then((params: StorageParams) => storage().upload(params, file, fileName, progressCallBack))
    .catch((error: Error) => {
      throw error;
    });

const uploadFileToWebStorage = async (
  file: File,
  fileName: string,
  progressCallBack: (bytes: number) => void
): Promise<void> => {
  await getWebStorageParams()
    .then(async (params: StorageParams) => {
      await storage().upload(params, file, fileName, progressCallBack);
    })
    .catch((error: Error) => {
      throw error;
    });
};

const getImageFromStorage = async (fileName: string, simulationId: string): Promise<string> =>
  getSimulationsStorageParams(simulationId)
    .then(async (params: StorageParams) => {
      const blob = await storage().download(params, fileName);
      return window.URL.createObjectURL(blob);
    })
    .catch((error: Error) => {
      throw error;
    });

export {
  getSimulationsStorageParams,
  uploadImageToStorage,
  getImageFromStorage,
  uploadFileToWebStorage,
};
