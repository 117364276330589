import { AxiosResponse } from "axios";

import { WebserviceResponse } from "./../types";

/**
 * Checks if the received response is valid.
 * @param {AxiosResponse} response The response received.
 * @returns {unknown} If the state equals to true, we return the data.value, which may vary according to the request that was sent.
 */
export const isResponseValid = (response: AxiosResponse): unknown => {
  const data = response.data as WebserviceResponse;
  if (!data.state) throw new Error(response.statusText);
  return data.value;
};
