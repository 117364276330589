import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faVrCardboard,
  faGlobe,
  faPlusSquare,
  faMinusSquare,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

import { Simulation, Platform, SimulationImageLink } from "../../utilities/types";
import {
  SimulationCardVariants,
  SimulationPlatformType,
} from "../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import { onClickUrl } from "../../utilities/openNewTab";
import { SIMULATION } from "./../../constants";

import imageSolid from "../../assets/image-solid.svg";
import "./styles.scss";

type Props = {
  index: number;
  simulation: Simulation;
  variant: SimulationCardVariants;
  image: SimulationImageLink;
  isSimulationDeleted: boolean;
  handleUploadVR?: () => void;
  handleUploadWeb?: () => void;
  handleUploadImage?: () => void;
  handleDownloadData?: () => void;
  handleEditModalOpen?: () => void;
  handleDeleteModalOpen?: () => void;
  handleDeviceLibraryAdd?: () => void;
  handleDeviceLibraryRemove?: () => void;
  handleRestore?: () => void;
  setSelectedSimulation?: (simulation: Simulation) => void;
};

const SimulationCard: React.FunctionComponent<Props> = ({
  index,
  simulation,
  variant,
  image,
  isSimulationDeleted,
  handleUploadVR = (): void => {},
  handleUploadWeb = (): void => {},
  handleUploadImage = (): void => {},
  handleDownloadData = (): void => {},
  handleEditModalOpen = (): void => {},
  handleDeleteModalOpen = (): void => {},
  handleDeviceLibraryAdd = (): void => {},
  handleDeviceLibraryRemove = (): void => {},
  handleRestore = () => (): void => {},
  setSelectedSimulation = (simulation: Simulation): void => {},
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [imageDisplay, setImageDisplay] = useState<string | undefined>(undefined);

  const isMounted = useIsMounted();

  /**
   * If image is undefined, it means it is still being loaded.
   * If image is null, it means the simulation has no image, so we use a default image.
   * Else, we have the image's link.
   */
  useEffect(() => {
    if (image === undefined) return;
    if (image === null) {
      if (isMounted()) setImageDisplay(imageSolid);
    } else {
      if (isMounted()) setImageDisplay(image);
    }
  }, [image, isMounted]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setSelectedSimulation(simulation);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const HeaderTitle = (): React.ReactNode => {
    const hasVR = simulation.platforms.some(
      (platform: Platform) => platform.type === SimulationPlatformType.VR
    );
    const hasWeb = simulation.platforms.some(
      (platform: Platform) =>
        platform.type === SimulationPlatformType.Web
    );
    const webUrl: string | undefined = simulation.platforms.find(
      (platform: Platform) => platform.type === SimulationPlatformType.Web
    )?.data?.url;

    const chipVRTitle = hasVR
      ? SIMULATION.CARD.HEADER.TITLE.VR_CHIP.UPLOADED
      : SIMULATION.CARD.HEADER.TITLE.VR_CHIP.NOT_UPLOADED;
    const chipWebTitle = hasWeb
      ? SIMULATION.CARD.HEADER.TITLE.WEB_CHIP.UPLOADED
      : SIMULATION.CARD.HEADER.TITLE.WEB_CHIP.NOT_UPLOADED;

    return (
      <div className="headerTitle">
        <Typography component="div" variant="h5" className="text">
          {simulation.name}
        </Typography>
        <div className="chips">
          <Chip
            data-testid="chipVR"
            icon={<FontAwesomeIcon icon={faVrCardboard} size="2x" />}
            className="vr"
            disabled={!hasVR}
            title={chipVRTitle}
          />
          <Chip
            data-testid="chipWeb"
            icon={<FontAwesomeIcon icon={faGlobe} size="2x" />}
            className="web"
            disabled={!hasWeb}
            title={chipWebTitle}
            {...(webUrl && { onClick: onClickUrl(webUrl) })}
          />
        </div>
      </div>
    );
  };

  const Loading = (): React.ReactNode => (
    <Skeleton
      data-testid="cardMedia"
      variant="rectangular"
      width="100%"
      height={200}
      animation="pulse"
    />
  );

  const ImageElement = (): React.ReactNode => {
    const imageClass: string = image && imageDisplay ? "withImage" : "nullImage";
    return (
      <CardMedia
        data-testid="cardMedia"
        className={`cardMedia ${imageClass}`}
        component="img"
        src={imageDisplay}
        alt={`${simulation.name} image`}
      />
    );
  };

  const handleUploadVROnClick = (): void => {
    handleUploadVR();
    handleClose();
  };

  const handleUploadWebOnClick = (): void => {
    handleUploadWeb();
    handleClose();
  };

  const handleUploadImageOnClick = (): void => {
    handleUploadImage();
    handleClose();
  };

  const handleDownloadOnClick = (): void => {
    handleDownloadData();
    handleClose();
  };

  const handleEditOnClick = (): void => {
    handleEditModalOpen();
    handleClose();
  };

  const handleDeleteOnClick = (): void => {
    handleDeleteModalOpen();
    handleClose();
  };

  const handleRestoreOnClick = (): void => {
    handleRestore();
    handleClose();
  };

  const HeaderActionButton = (): React.ReactNode => {
    if (variant === SimulationCardVariants.ShowMenu) {
      return (
        <IconButton
          data-testid="simulationMenuButton"
          id={`simulationMenuButton${index}`}
          aria-controls={`simulationMenu${index}`}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          onClick={handleMenu}
        >
          <FontAwesomeIcon icon={faEllipsisV} size="lg" />
        </IconButton>
      );
    }

    const isAdd: boolean = variant === SimulationCardVariants.Add;
    const icon: IconDefinition = isAdd ? faPlusSquare : faMinusSquare;
    const title: string = isAdd
      ? SIMULATION.CARD.HEADER.ACTION_BUTTON.ADD
      : SIMULATION.CARD.HEADER.ACTION_BUTTON.REMOVE;
    const className: string = `simulationButton${isAdd ? "Add" : "Remove"}`;
    const handleClick = isAdd ? handleDeviceLibraryAdd : handleDeviceLibraryRemove;

    return (
      <IconButton
        data-testid="simulationManageButton"
        id={`simulationManageButton${index}`}
        onClick={handleClick}
        title={title}
        className={className}
      >
        <FontAwesomeIcon icon={icon} size="lg" />
      </IconButton>
    );
  };

  const ImageUploadMenuItem = (): React.ReactNode => (
    <Tooltip
      classes={{ tooltip: "customTooltipDisplay" }}
      placement="left"
      title={SIMULATION.CARD.IMAGE_UPLOAD_HELPER}
    >
      <MenuItem onClick={handleUploadImageOnClick}>{SIMULATION.CARD.OPTIONS.IMAGE}</MenuItem>
    </Tooltip>
  );

  return (
    <Fade in>
      <Card raised className="SimulationCard" data-testid="simulationCard">
        <Grid container>
          <Grid item md={3} xl={4} sx={{ display: { xs: "none", md: "block" } }}>
            {imageDisplay === undefined ? Loading() : ImageElement()}
          </Grid>
          <Grid item xs={12} md={9} xl={8}>
            <CardHeader
              data-testid="cardHeader"
              className="cardHeader"
              action={HeaderActionButton()}
              title={HeaderTitle()}
            />
            <CardContent className="cardContent" data-testid="cardContent">
              <Typography
                variant="subtitle1"
                component="div"
                className="description customScrollbar"
              >
                {simulation.description}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
        {variant === SimulationCardVariants.ShowMenu && !isSimulationDeleted && (
          <Menu
            data-testid="simulationMenu"
            id={`simulationMenu${index}`}
            MenuListProps={{ "aria-labelledby": `simulationMenuButton${index}` }}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleUploadVROnClick}>{SIMULATION.CARD.OPTIONS.VR}</MenuItem>
            <MenuItem onClick={handleUploadWebOnClick}>{SIMULATION.CARD.OPTIONS.WEB}</MenuItem>
            {ImageUploadMenuItem()}
            <MenuItem onClick={handleDownloadOnClick}>{SIMULATION.CARD.OPTIONS.DOWNLOAD}</MenuItem>
            <MenuItem onClick={handleEditOnClick}>{SIMULATION.CARD.OPTIONS.EDIT}</MenuItem>
            <MenuItem onClick={handleDeleteOnClick}>{SIMULATION.CARD.OPTIONS.DELETE}</MenuItem>
          </Menu>
        )}
        {variant === SimulationCardVariants.ShowMenu && isSimulationDeleted && (
          <Menu
            data-testid="simulationMenuRestore"
            id={`simulationMenu${index}`}
            MenuListProps={{ "aria-labelledby": `simulationMenuButton${index}` }}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleRestoreOnClick}>{SIMULATION.CARD.OPTIONS.RESTORE}</MenuItem>
          </Menu>
        )}
      </Card>
    </Fade>
  );
};

export default SimulationCard;
