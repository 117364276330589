import React from "react";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import "./styles.scss";

const PageLoader: React.FunctionComponent = () => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="center"
    className="PageLoader"
    data-testid="pageLoader"
  >
    <CircularProgress className="progress" />
  </Grid>
);

export default PageLoader;
