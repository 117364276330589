/**
 * Method to open a link in a new tab.
 * @param {string} url The url to be opened.
 * @returns {void} Nothing is returned.
 */
const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/**
 * Function that wrapps the openInNewTab method, returning a function.
 * @param {string} url The url to be opened.
 * @returns {(() => void)} The function wrapping the openInNewTab method.
 */
const onClickUrl =
  (url: string): (() => void) =>
  () =>
    openInNewTab(url);

export { openInNewTab, onClickUrl };
