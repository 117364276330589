import React, { useState, useRef } from "react";
import { CSVLink } from "react-csv";

import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Modal from "../Modal";

import { getSimulationSurveyData } from "../../gateway/Simulation";
import { SimulationSurveyDataParams } from "../../utilities/types";
import { MessageVariants } from "../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import { handleCheckedInputOnChange } from "./../../utilities/inputHelper";
import { MESSAGES } from "../../constants";

type Props = {
  title?: string;
  simulationId?: string;
  confirmText: string;
  open: boolean;
  handleModalClose: () => void;
  handleModalConfirm: () => void;
};

const DownloadDataModal: React.FunctionComponent<Props> = ({
  title,
  simulationId,
  confirmText,
  open,
  handleModalClose,
  handleModalConfirm,
}) => {
  const [questionnaire, setQuestionnaire] = useState<boolean>(true);
  const [interaction, setInteraction] = useState<boolean>(false);
  const [data, setData] = useState<object[]>([]);

  const isMounted = useIsMounted();
  const message = useMessage();

  const handleExportButton = async () => {
    if (!simulationId) return;
    try {
      const params: SimulationSurveyDataParams = {
        includeQuestionnaire: questionnaire,
        includeInteraction: interaction,
      };
      const data: object[] = await getSimulationSurveyData(simulationId, params);
      if (isMounted()) {
        setData(data);
        csvLinkRef?.current?.link.click();
        message(MESSAGES.SIMULATION.DOWNLOAD_DATA_SUCCESS, MessageVariants.Success);
      }
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.DOWNLOAD_DATA_ERROR,
        MESSAGES.SIMULATION.DOWNLOAD_DATA_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
    handlePostDataDownload();
  };

  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const CheckboxLabel = (text: string): React.ReactNode => (
    <Typography component="div" variant="h6" data-testid="checkboxLabel">
      {text}
    </Typography>
  );

  const reset = (): void => {
    setQuestionnaire(true);
    setInteraction(false);
    setData([]);
  };

  const handleClose = (): void => {
    handleModalClose();
    reset();
  };

  const handlePostDataDownload = (): void => {
    handleModalConfirm();
    reset();
  };

  const handleConfirm = (): void => {
    handleExportButton();
  };

  return (
    <Modal
      title={title}
      confirmText={confirmText}
      open={open}
      danger={false}
      big={false}
      hideCancel
      confirmDisabled={!questionnaire && !interaction}
      handleModalClose={handleClose}
      handleModalConfirm={handleConfirm}
    >
      <FormControl sx={{ m: 3 }} variant="standard" focused={false}>
        <FormLabel sx={{ mb: 1 }}>Select the data to be downloaded.</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={questionnaire}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleCheckedInputOnChange(event, setQuestionnaire)
                }
              />
            }
            label={CheckboxLabel("Questionnaire Data")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={interaction}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleCheckedInputOnChange(event, setInteraction)
                }
              />
            }
            label={CheckboxLabel("Interaction Data")}
          />
        </FormGroup>
        <CSVLink ref={csvLinkRef} data={data} filename={`data_${title}.csv`} />
      </FormControl>
    </Modal>
  );
};

export default DownloadDataModal;
