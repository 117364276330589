import React from "react";
import { Link } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import "./styles.scss";

type Props = {
  to: string;
  text: string;
  selected: boolean;
  icon: React.ReactNode | string;
};

const CustomListItem: React.FunctionComponent<Props> = ({ to, text, selected, icon }) => (
  <ListItemButton className="CustomListItem" component={Link} to={to} selected={selected}>
    <ListItemIcon className="icon">{icon}</ListItemIcon>
    <ListItemText className="text" primary={text} />
  </ListItemButton>
);

export default CustomListItem;
