import React from "react";
import { Switch, Route } from "react-router-dom";

import Navbar from "../../components/Navbar";
import SideMenu from "../../components/SideMenu";

import { RouteData } from "./../../utilities/types";
import { ROUTES_DATA, APP_NAME } from "./../../constants";

import "./styles.scss";

type Props = {
  userName: string | undefined;
  handleLogoClick: () => void;
  handleLogout: () => Promise<void>;
};

const App: React.FunctionComponent<Props> = ({ userName, handleLogoClick, handleLogout }) => (
  <div className="App">
    <Navbar
      text={APP_NAME}
      userName={userName}
      handleLogoClick={handleLogoClick}
      handleLogout={handleLogout}
    />
    <SideMenu items={ROUTES_DATA} />
    <main className="content">
      <Switch>
        {ROUTES_DATA.map((data: RouteData) => (
          <Route key={data.name} exact path={data.routePaths} component={data.component} />
        ))}
      </Switch>
    </main>
  </div>
);

export default App;
