import axios, { AxiosResponse } from "axios";

import { WEB_SERVICE_URL } from "../../constants";
import {
  Simulation,
  SimulationUpdateData,
  SimulationInsertData,
  SimulationPlatformInsertData,
  SimulationGetParams,
  SimulationDeleteParams,
  SimulationSurveyDataParams,
  Platform,
} from "../../utilities/types";
import { isResponseValid } from "../../utilities/gatewayHelper";

const getAllSimulations = async (params?: SimulationGetParams): Promise<Simulation[]> =>
  axios
    .get(`${WEB_SERVICE_URL}/simulations`, { params })
    .then((response: AxiosResponse) => {
      const simulations: Simulation[] = isResponseValid(response) as Simulation[];
      return simulations;
    })
    .catch((error: Error) => {
      throw error;
    });

const updateSimulation = async (id: string, data: SimulationUpdateData): Promise<void> =>
  axios
    .put(`${WEB_SERVICE_URL}/simulations/${id}`, data)
    .then((response: AxiosResponse) => {
      isResponseValid(response);
    })
    .catch((error: Error) => {
      throw error;
    });

const insertSimulation = async (data: SimulationInsertData): Promise<Simulation> =>
  axios
    .post(`${WEB_SERVICE_URL}/simulations`, data)
    .then((response: AxiosResponse) => {
      const simulation: Simulation = isResponseValid(response) as Simulation;
      return simulation;
    })
    .catch((error: Error) => {
      throw error;
    });

const deleteSimulation = async (id: string, params?: SimulationDeleteParams): Promise<void> =>
  axios
    .delete(`${WEB_SERVICE_URL}/simulations/${id}`, { params })
    .then((response: AxiosResponse) => {
      isResponseValid(response);
    })
    .catch((error: Error) => {
      throw error;
    });

const insertSimulationPlatform = async (
  id: string,
  data: SimulationPlatformInsertData
): Promise<Platform> =>
  axios
    .post(`${WEB_SERVICE_URL}/simulations/${id}/platforms`, data)
    .then((response: AxiosResponse) => {
      const platform: Platform = isResponseValid(response) as Platform;
      return platform;
    })
    .catch((error: Error) => {
      throw error;
    });

const getSimulationSurveyData = async (
  id: string,
  params?: SimulationSurveyDataParams
): Promise<object[]> =>
  axios
    .get(`${WEB_SERVICE_URL}/simulations/${id}/survey`, { params })
    .then((response: AxiosResponse) => {
      const surveyData: object[] = isResponseValid(response) as object[];
      return surveyData;
    })
    .catch((error: Error) => {
      throw error;
    });

export {
  getAllSimulations,
  updateSimulation,
  insertSimulation,
  deleteSimulation,
  insertSimulationPlatform,
  getSimulationSurveyData,
};
