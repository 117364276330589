/**
 * A simulation card can have three variants: ShowMenu, Add or Remove.
 * They affect the card's header, with `ShowMenu` having a context menu, `Add` having a green plus iconed button, and `Remove` having a red minus iconed button.
 */
export enum SimulationCardVariants {
  ShowMenu = "ShowMenu",
  Add = "Add",
  Remove = "Remove",
}

export enum SimulationPlatformType {
  VR = "VR",
  Web = "Web",
}

export enum MessageVariants {
  Success = "success",
  Error = "error",
}

export enum PlatformWebUploadStatus {
  InQueue = "InQueue",
  InProgress = "InProgress",
  Success = "Success",
  Fail = "Fail",
}

export enum SimulationDisplay {
  NonDeleted = "NonDeleted",
  Deleted = "Deleted",
}
