import { useCallback, useEffect, useRef } from "react";

/**
 * Helper hook to check if the component is mounted.
 * This is used so that we avoid the "Can't perform a React state update on an unmounted component" issue.
 * @returns {(() => boolean)} A function returning true or false.
 */
const useIsMounted = (): (() => boolean) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

export default useIsMounted;
