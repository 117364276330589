import React, { useEffect, useState, useCallback } from "react";

import Container from "@mui/material/Container";
import DeviceCardList from "../../components/DeviceCardList";
import PaginationSection from "../../components/PaginationSection";
import InitialSection from "../../components/InitialSection";
import PageLoader from "../../components/PageLoader";

import { getAllDevices } from "../../gateway/Device";
import { Device, DeviceUpdateData } from "./../../utilities/types";
import { MessageVariants } from "./../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import { handleInputOnChange } from "./../../utilities/inputHelper";
import { getPagesCount, itemsToRender, filterItemsPerName } from "./../../utilities/pagination";
import { MESSAGES, DEVICES_PER_PAGE, DEVICE } from "./../../constants";

const Devices: React.FunctionComponent = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const isMounted = useIsMounted();
  const message = useMessage();

  /**
   * Devices are fetched and ordered by the creationDate field (newest first)
   */
  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const allDevices = await getAllDevices();
      allDevices.sort(
        (a: Device, b: Device) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );
      if (isMounted()) setDevices(allDevices);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.DEVICE.FETCH_ERROR,
        MESSAGES.DEVICE.FETCH_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
    if (isMounted()) setLoading(false);
  }, [isMounted, message]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredDevices = !filter ? devices : filterItemsPerName(devices, filter);

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleSpecificDelete = useCallback(
    (id: string): void => {
      const newDevices = devices.filter((device: Device) => device.id !== id);
      if (isMounted()) setDevices(newDevices);
    },
    [devices, isMounted]
  );

  const handleSpecificUpdate = useCallback(
    (id: string, deviceData: DeviceUpdateData): void => {
      const newDevices: Device[] = [...devices];
      const index: number = devices.findIndex((device: Device) => device.id === id);
      newDevices[index] = { ...newDevices[index], ...deviceData };
      if (isMounted()) setDevices(newDevices);
    },
    [devices, isMounted]
  );

  const Loading = (): React.ReactNode => <PageLoader />;

  const CardList = (): React.ReactNode => (
    <DeviceCardList
      devices={itemsToRender(filteredDevices, page, DEVICES_PER_PAGE) as Device[]}
      handleSpecificDelete={handleSpecificDelete}
      handleSpecificUpdate={handleSpecificUpdate}
    />
  );

  return (
    <div data-testid="devicesContent">
      <Container maxWidth="xl" disableGutters>
        <InitialSection
          pageTitle={DEVICE.PAGE_TITLE}
          displayFilter
          onFilterChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputOnChange(event, setFilter)
          }
          displayModeSelector={false}
        />
        {loading ? Loading() : CardList()}
        <PaginationSection
          page={page}
          count={getPagesCount(filteredDevices, DEVICES_PER_PAGE)}
          onChange={onPaginationChange}
        />
      </Container>
    </div>
  );
};

export default Devices;
