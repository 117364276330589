import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";

import "./styles.scss";

type Props = {
  children: React.ReactNode;
  title?: string;
  cancelText?: string;
  confirmText: string;
  open: boolean;
  danger?: boolean;
  big?: boolean;
  hideCancel?: boolean;
  confirmDisabled?: boolean;
  handleModalClose: () => void;
  handleModalConfirm: () => void;
};

const Modal: React.FunctionComponent<Props> = ({
  children,
  title,
  cancelText,
  confirmText,
  open,
  danger,
  big,
  hideCancel,
  confirmDisabled,
  handleModalClose,
  handleModalConfirm,
}) => (
  <Dialog
    open={open}
    onClose={handleModalClose}
    data-testid="modal"
    className="Modal"
    classes={{ paper: `modalWidth ${big ? "big" : ""}` }}
  >
    {title && (
      <DialogTitle className={`title ${big ? "big" : ""}`} data-testid="title">
        <Typography component="div" variant="h5" className="text">
          {danger && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="lg"
              className="dangerIcon"
              data-testid="dangerIcon"
            />
          )}
          {title}
        </Typography>
      </DialogTitle>
    )}
    <DialogContent classes={{ root: "hiddenOverflowY" }}>{children}</DialogContent>
    <DialogActions className="actions">
      {!hideCancel && (
        <Button
          data-testid="cancelButton"
          className="cancelButton"
          size="large"
          onClick={handleModalClose}
        >
          {cancelText}
        </Button>
      )}
      <Button
        data-testid="confirmButton"
        className={`confirmButton ${danger ? "danger" : ""}`}
        size="large"
        onClick={handleModalConfirm}
        disabled={confirmDisabled}
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default Modal;
