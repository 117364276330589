import React, { useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import createInstance from "../auth/instance";

import { AuthParams } from "../utilities/types";
import getAuthParams from "../gateway/Auth";

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [instance, setInstance] = useState<PublicClientApplication | undefined>(undefined);

  /**
   * Should set the instance when it is undefined.
   */
  useEffect(() => {
    if (instance) return;

    const fetchAuthParams = async (): Promise<void> => {
      try {
        const authParams: AuthParams = await getAuthParams();
        const config: Configuration = {
          auth: {
            clientId: authParams.clientId,
            authority: `https://login.microsoftonline.com/${authParams.tenantId}`,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
          },
          cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
          },
        };
        const createdInstance: PublicClientApplication = createInstance(config);
        setInstance(createdInstance);
      } catch (error: unknown) {
        console.error(error);
      }
    };

    fetchAuthParams();
  }, [instance, setInstance]);

  return instance ? <MsalProvider instance={instance}>{children}</MsalProvider> : <></>;
};

export default AuthProvider;
