import React, { useState, useEffect, useCallback } from "react";

import Grid from "@mui/material/Grid";
import DeviceCard from "../DeviceCard";
import EmptyListPlaceholder from "../EmptyListPlaceholder";
import DeleteModal from "../DeleteModal";
import SimulationLinkModal from "../SimulationLinkModal";
import NotificationModal from "../NotificationModal";

import { getAllSimulations } from "../../gateway/Simulation";
import { deleteDevice, insertIntoLibrary, deleteFromLibrary } from "../../gateway/Device";
import {
  Device,
  DeviceUpdateData,
  Simulation,
  SimulationGetParams,
  SimulationIntoLibraryData,
} from "../../utilities/types";
import { MessageVariants, SimulationPlatformType } from "../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import { MESSAGES, DEVICE } from "../../constants";

import "./styles.scss";

type Props = {
  devices: Device[];
  handleSpecificDelete: (id: string) => void;
  handleSpecificUpdate: (id: string, deviceData: DeviceUpdateData) => void;
};

const DeviceCardList: React.FunctionComponent<Props> = ({
  devices,
  handleSpecificDelete,
  handleSpecificUpdate,
}) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [manageModalOpen, setManageModalOpen] = useState<boolean>(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined);
  const [otherSimulations, setOtherSimulations] = useState<Simulation[]>([]);
  const [loadingSimulationData, setLoadingSimulationData] = useState<boolean>(true);

  const isMounted = useIsMounted();
  const message = useMessage();

  /**
   * Simulations containing a VR platform file are fetched, when manageModalOpen is true, and shouldFetch is also true.
   * The simulations already on the device library are removed from these fetched Simulations, and sorted by creationDate, composing the Other simulations array.
   */
  useEffect(() => {
    if (!manageModalOpen || !shouldFetch) return;
    setLoadingSimulationData(true);
    const fetchSimulationData = async (): Promise<void> => {
      try {
        const params: SimulationGetParams = { platform: SimulationPlatformType.VR };
        const vrSimulations: Simulation[] = await getAllSimulations(params);

        const deviceLibrary: Simulation[] = selectedDevice ? selectedDevice.simulations : [];
        const otherOptions = vrSimulations.filter(
          (simulation: Simulation) =>
            !deviceLibrary.find(
              (librarySimulation: Simulation) => librarySimulation.id === simulation.id
            )
        );
        otherOptions.sort(
          (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
        );

        if (isMounted()) {
          setOtherSimulations(otherOptions);
          setShouldFetch(false);
          setLoadingSimulationData(false);
        }
      } catch (error: unknown) {
        const messageText = checkMessageText(
          error as Error,
          MESSAGES.DEVICE.SIMULATION_FETCH_ERROR,
          MESSAGES.DEVICE.SIMULATION_FETCH_ERROR_AUTH
        );
        message(messageText, MessageVariants.Error);
      }
    };
    fetchSimulationData();
  }, [manageModalOpen, shouldFetch, selectedDevice, isMounted, message]);

  /**
   * Should update the Device's simulations and the Other simulations after adding a simulation to the device library.
   */
  const handleDeviceSimulationInsertion = useCallback(
    (newSimulation: Simulation, selectedDeviceCurrent: Device): void => {
      const newSimulations: Simulation[] = [...selectedDeviceCurrent.simulations];
      newSimulations.unshift(newSimulation);
      newSimulations.sort(
        (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );

      /**
       * Updating the selected device.
       */
      const updatedDevice: Device = { ...selectedDeviceCurrent };
      updatedDevice.simulations = newSimulations;
      if (isMounted()) setSelectedDevice(updatedDevice);

      /**
       * Updating the other simulations.
       */
      const currentOther: Simulation[] = [...otherSimulations];
      const newOther: Simulation[] = currentOther.filter(
        (simulation: Simulation) => simulation.id !== newSimulation.id
      );
      if (isMounted()) setOtherSimulations(newOther);
    },
    [isMounted, otherSimulations]
  );

  /**
   * Should update the Device's simulations and the Other Simulations after removing a simulation from the device library.
   */
  const handleDeviceSimulationDelete = useCallback(
    (simulationId: string, selectedDeviceCurrent: Device): void => {
      const currentSimulations: Simulation[] = [...selectedDeviceCurrent.simulations];
      const newSimulations: Simulation[] = currentSimulations.filter(
        (simulation: Simulation) => simulation.id !== simulationId
      );

      /**
       * Updating the selected device.
       */
      const updatedDevice: Device = { ...selectedDeviceCurrent };
      updatedDevice.simulations = newSimulations;
      if (isMounted()) setSelectedDevice(updatedDevice);

      /**
       * Updating the other simulations.
       */
      const newOther: Simulation[] = [...otherSimulations];
      const newOtherSimulation: Simulation | undefined = currentSimulations.find(
        (simulation: Simulation) => simulation.id === simulationId
      );
      if (newOtherSimulation) newOther.unshift(newOtherSimulation);
      newOther.sort(
        (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );
      if (isMounted()) setOtherSimulations(newOther);
    },
    [isMounted, otherSimulations]
  );

  /**
   * Calls the endpoint to add a simulation to the device's library.
   * Calls handleDeviceSimulationInsertion to update the device's simulations.
   */
  const handleDeviceLibraryAdd = async (simulationId: string): Promise<void> => {
    if (!selectedDevice) return;
    try {
      const insertData: SimulationIntoLibraryData = { simulationId };
      const addedSimulation: Simulation = await insertIntoLibrary(selectedDevice.id, insertData);
      handleDeviceSimulationInsertion(addedSimulation, selectedDevice);
      message(MESSAGES.DEVICE.ADD_TO_LIBRARY_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.DEVICE.ADD_TO_LIBRARY_ERROR,
        MESSAGES.DEVICE.ADD_TO_LIBRARY_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  /**
   * Calls the endpoint to remove a simulation from the device's library.
   * Calls handleDeviceSimulationDelete to update the device's simulations.
   */
  const handleDeviceLibraryRemove = async (simulationId: string): Promise<void> => {
    if (!selectedDevice) return;
    try {
      await deleteFromLibrary(selectedDevice.id, simulationId);
      handleDeviceSimulationDelete(simulationId, selectedDevice);
      message(MESSAGES.DEVICE.REMOVE_FROM_LIBRARY_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.DEVICE.REMOVE_FROM_LIBRARY_ERROR,
        MESSAGES.DEVICE.REMOVE_FROM_LIBRARY_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const handleManageModalOpen = (): void => {
    setManageModalOpen(true);
  };

  /**
   * Closing the modal updates the devices list, and resets the shouldFetch boolean.
   */
  const handleManageModalClose = (): void => {
    if (selectedDevice) {
      const deviceData: DeviceUpdateData = { simulations: selectedDevice.simulations };
      handleSpecificUpdate(selectedDevice.id, deviceData);
    }
    setShouldFetch(true);
    setManageModalOpen(false);
    setOtherSimulations([]);
    handleNotificationModalOpen();
  };

  const handleDeleteModalOpen = (): void => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = (): void => {
    setDeleteModalOpen(false);
  };

  const handleNotificationModalOpen = (): void => {
    setNotificationModalOpen(true);
  };

  const handleNotificationModalClose = (): void => {
    setNotificationModalOpen(false);
  };

  const handleDeleteModalConfirm = async (): Promise<void> => {
    if (!selectedDevice) return;
    try {
      handleDeleteModalClose();
      await deleteDevice(selectedDevice.id);
      handleSpecificDelete(selectedDevice.id);
      message(MESSAGES.DEVICE.DELETE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.DEVICE.DELETE_ERROR,
        MESSAGES.DEVICE.DELETE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const EmptyList = (): React.ReactNode => (
    <EmptyListPlaceholder text={DEVICE.EMPTY_LIST_PLACEHOLDER} />
  );

  const DeviceCards = (): React.ReactNode =>
    devices.map((device: Device, index: number) => (
      <Grid key={device.id} item xs={12} lg={6} xl={3}>
        <DeviceCard
          index={index}
          device={device}
          handleManageModalOpen={handleManageModalOpen}
          handleDeleteModalOpen={handleDeleteModalOpen}
          setSelectedDevice={setSelectedDevice}
        />
      </Grid>
    ));

  return (
    <Grid container spacing={2} className="DeviceCardList" data-testid="deviceCardList">
      {devices.length < 1 ? EmptyList() : DeviceCards()}
      <DeleteModal
        title={DEVICE.MODAL.DELETE.TITLE}
        cancelText={DEVICE.MODAL.DELETE.CANCEL}
        confirmText={DEVICE.MODAL.DELETE.CONFIRM}
        open={deleteModalOpen}
        contentText={DEVICE.MODAL.DELETE.CONTENT}
        handleModalClose={handleDeleteModalClose}
        handleModalConfirm={handleDeleteModalConfirm}
      />
      <SimulationLinkModal
        confirmText={DEVICE.MODAL.SIMULATION_LINK.CONFIRM}
        open={manageModalOpen}
        addedSimulations={selectedDevice ? selectedDevice.simulations : []}
        availableSimulations={otherSimulations}
        device={selectedDevice}
        loadingSimulationData={loadingSimulationData}
        handleDeviceLibraryAdd={handleDeviceLibraryAdd}
        handleDeviceLibraryRemove={handleDeviceLibraryRemove}
        handleModalClose={handleManageModalClose}
        handleModalConfirm={handleManageModalClose}
      />
      <NotificationModal
        title={DEVICE.MODAL.NOTIFICATION.TITLE}
        confirmText={DEVICE.MODAL.NOTIFICATION.CONFIRM}
        open={notificationModalOpen}
        contentText={DEVICE.MODAL.NOTIFICATION.CONTENT}
        handleModalClose={handleNotificationModalClose}
        handleModalConfirm={handleNotificationModalClose}
      />
    </Grid>
  );
};

export default DeviceCardList;
