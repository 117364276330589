import React, { useState } from "react";
import { format } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";

import { Device } from "../../utilities/types";
import { localizeDate } from "../../utilities/dateHelper";
import { DEVICE } from "../../constants";

import picoNeo3 from "../../assets/m-neo3-7.jpg";
import "./styles.scss";

type Props = {
  index: number;
  device: Device;
  handleManageModalOpen: () => void;
  handleDeleteModalOpen: () => void;
  setSelectedDevice: (device: Device) => void;
};

const DeviceCard: React.FunctionComponent<Props> = ({
  index,
  device,
  handleManageModalOpen,
  handleDeleteModalOpen,
  setSelectedDevice,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setSelectedDevice(device);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const ImageElement = (): React.ReactNode => (
    <CardMedia
      data-testid="cardMedia"
      className="cardMedia"
      component="img"
      src={picoNeo3}
      alt={`${device.name} image`}
    />
  );

  const handleManageOnClick = (): void => {
    handleManageModalOpen();
    handleClose();
  };

  const handleDeleteOnClick = (): void => {
    handleDeleteModalOpen();
    handleClose();
  };

  return (
    <Fade in>
      <Card raised className="DeviceCard" data-testid="deviceCard">
        <Grid container className="topContainer">
          <Grid item xs={1} />
          <Grid item xs={10} className="imageGrid">
            {ImageElement()}
          </Grid>
          <Grid item xs={1} className="menuGrid">
            <IconButton
              data-testid="deviceMenuButton"
              id={`deviceMenuButton${index}`}
              aria-controls={`deviceMenu${index}`}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
              onClick={handleMenu}
            >
              <FontAwesomeIcon icon={faEllipsisV} size="lg" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container className="bottomContainer">
          <Grid item xs={1} />
          <Grid item xs={10} className="content">
            <CardContent className="cardContent" data-testid="cardContent">
              <Typography component="div" variant="h5" className="title" noWrap title={device.name}>
                {device.name}
              </Typography>
              <Typography variant="subtitle1" component="div" className="information" mt={1}>
                &#9658;{" "}
                {device.simulations.length === 1
                  ? `${device.simulations.length} simulation`
                  : `${device.simulations.length} simulations`}
              </Typography>
              <Typography variant="subtitle1" component="div" className="information">
                &#9658; Last online at{" "}
                {format(localizeDate(device.lastOnlineDate), "yyyy/MM/dd HH:mm a")}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Menu
          data-testid="deviceMenu"
          id={`deviceMenu${index}`}
          MenuListProps={{ "aria-labelledby": `deviceMenuButton${index}` }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleManageOnClick}>{DEVICE.CARD.OPTIONS.MANAGE}</MenuItem>
          <MenuItem onClick={handleDeleteOnClick}>{DEVICE.CARD.OPTIONS.DELETE}</MenuItem>
        </Menu>
      </Card>
    </Fade>
  );
};

export default DeviceCard;
