import { Element, Device, Simulation, WebPassword } from "./../../utilities/types";
import { SIMULATIONS_PER_PAGE } from "./../../constants";

/**
 * Calculates the quantity of pages, based on the quantity of elements, and how many element should be displayed by page.
 * @param {Element[]} array The elements array.
 * @param {number} perPage The quantity of elements to be displayed per page.
 * @returns {number} The quantity of pages.
 */
const getPagesCount = (array: Element[], perPage: number = SIMULATIONS_PER_PAGE): number =>
  Math.ceil(array.length / perPage);

/**
 * Defines which elements should be displayed on the specific page the user is at.
 * @param {Element[]} array The elements array.
 * @param {number} currentPage The current page.
 * @param {number} perPage The quantity of elements to be displayed per page.
 * @returns {number} The items to be rendered for the given page.
 */
const itemsToRender = (
  array: Element[],
  currentPage: number,
  perPage: number = SIMULATIONS_PER_PAGE
): Element[] => {
  const currentPageIndex = currentPage - 1;
  const firstIndex = currentPageIndex * perPage;
  const endIndex = firstIndex + perPage;
  return endIndex >= array.length
    ? array.slice(firstIndex, array.length)
    : array.slice(firstIndex, endIndex);
};

/**
 * Filters an array based on the filter. The filter will be compared to the item's name property.
 * @param {(Device | Simulation)[]} array An array of Devices or Simulations.
 * @param {string} filter The filter.
 * @returns {(Device | Simulation)[]} The items matching the filter.
 */
const filterItemsPerName = (array: (Device | Simulation)[], filter: string): (Device | Simulation)[] =>
  array.filter((item: Device | Simulation) => {
    if (!filter) return true;
    else if (item.name.includes(filter)) return true;
    else return false;
  });

/**
 * Filters an array based on the filter. The filter will be compared to the item's userType property.
 * @param {WebPassword[]} array An array of WebPasswords.
 * @param {string} filter The filter.
 * @returns {WebPassword[]} The items matching the filter.
 */
const filterWebPasswordsPerUserType = (array: WebPassword[], filter: string): WebPassword[] =>
  array.filter((item: WebPassword) => {
    if (!filter) return true;
    else if (item.userType.includes(filter)) return true;
    else return false;
  });

export { getPagesCount, itemsToRender, filterItemsPerName, filterWebPasswordsPerUserType };
