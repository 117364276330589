import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import logo from "../../assets/coc-logo.svg";
import "./styles.scss";

type Props = {
  text: string;
  userName: string | undefined;
  handleLogoClick: () => void;
  handleLogout: () => Promise<void>;
};

const Navbar: React.FunctionComponent<Props> = ({
  text,
  userName,
  handleLogoClick,
  handleLogout,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogoutOnClick = (): void => {
    handleClose();
    handleLogout();
  };

  const getUserNameInitials = (fullName: string | undefined): string => {
    if (!fullName) return "";

    const names = fullName.split(" ");
    const initials = [];
    const firstNameInitial = names[0].substring(0, 1).toUpperCase();
    initials.push(firstNameInitial);

    if (names.length > 1) {
      const lastNameInitial = names[names.length - 1].substring(0, 1).toUpperCase();
      initials.push(lastNameInitial);
    }

    return initials.join("");
  };

  return (
    <AppBar position="fixed" className="Navbar">
      <Toolbar className="toolbar">
        <img className="logo" src={logo} alt="City of Calgary logo" onClick={handleLogoClick} />
        <Typography ml={5} className="text" variant="h6" noWrap component="div">
          {text}
        </Typography>
        <div className="userCircle">
          <Button
            size="large"
            aria-label="Account of current user"
            aria-controls="menuAppbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar className="avatar">{getUserNameInitials(userName)}</Avatar>
            <Typography ml={1}>{userName}</Typography>
          </Button>
          <Menu
            id="menuAppbar"
            className="menuAppbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogoutOnClick}>Log off</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
