import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthenticatedTemplate, useMsalAuthentication, useMsal } from "@azure/msal-react";
import { InteractionType, EndSessionRequest } from "@azure/msal-browser";

import App from "../App";
import RequestInterceptor from "../../components/RequestInterceptor";

const AppAuthWrapper: React.FunctionComponent = () => {
  /**
   * Hooks methods/values declaration.
   */
  const history = useHistory();
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState<string | undefined>(undefined);

  /**
   * Displays the login page in case the user is not logged in.
   */
  useMsalAuthentication(InteractionType.Redirect);

  /**
   * Setting the userName state variable, to be used on the App component.
   */
  useEffect(() => {
    if (accounts.length === 0) return;
    const name: string | undefined = instance.getActiveAccount()?.name;
    setUserName(name);
  }, [instance, accounts]);

  const handleLogoClick = (): void => history.push("/");

  const handleLogout = async (): Promise<void> => {
    try {
      const logoutRequest: EndSessionRequest = { account: instance.getActiveAccount() };
      await instance.logoutRedirect(logoutRequest);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * The content inside AuthenticatedTemplate will only be displayed once the user is authenticated.
   */
  return (
    <AuthenticatedTemplate>
      <RequestInterceptor>
        <App userName={userName} handleLogoClick={handleLogoClick} handleLogout={handleLogout} />
      </RequestInterceptor>
    </AuthenticatedTemplate>
  );
};

export default AppAuthWrapper;
