import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "./styles.scss";

type Props = {
  text: string;
};

const EmptyListPlaceholder: React.FunctionComponent<Props> = ({ text }) => (
  <Grid
    container
    className="EmptyListPlaceholder"
    justifyContent="center"
    data-testid="emptyListPlaceholder"
  >
    <Typography variant="h6" className="text">
      {text}
    </Typography>
  </Grid>
);

export default EmptyListPlaceholder;
