import React from "react";

import cfdLogo from "../../assets/maltese-cross.jpg";
import redIronLogo from "../../assets/red-iron-logo.jpg";
import vizworxLogo from "../../assets/vizworx-horizontal-no-icon.png";
import copLogo from "../../assets/cop-logo.jpg";
import "./styles.scss";

const SideMenuBottomLogos: React.FunctionComponent = () => (
  <div className="SideMenuBottomLogos">
    <div className="sectionOne">
      <div className="item">
        <img
          data-testid="vizworxLogo"
          className="imageHorizontal"
          src={vizworxLogo}
          alt="VizworX logo"
          title="VizworX"
        />
      </div>
      <div className="item">
        <img
          data-testid="redIronLogo"
          className="image"
          src={redIronLogo}
          alt="Red Iron Labs logo"
          title="Red Iron Labs"
        />
      </div>
    </div>
    <div className="sectionTwo">
      <div className="item">
        <img
          data-testid="copLogo"
          className="imageHorizontal"
          src={copLogo}
          alt="ConocoPhillips logo"
          title="ConocoPhillips"
        />
      </div>
      <div className="item">
        <img
          data-testid="cfdLogo"
          className="image"
          src={cfdLogo}
          alt="Calgary Fire Department logo"
          title="Calgary Fire Department"
        />
      </div>
    </div>
  </div>
);

export default SideMenuBottomLogos;
