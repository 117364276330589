/**
 * This file exports the constants that are used throughout the application.
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVrCardboard, faFireAlt, faLock } from "@fortawesome/free-solid-svg-icons";
import Simulations from "./containers/Simulations";
import Devices from "./containers/Devices";
import WebPasswords from "./containers/WebPasswords";

import { version } from "./../package.json";
import { RouteData } from "./utilities/types";

const ROUTES_DATA: RouteData[] = [
  {
    name: "Simulations",
    linkPath: "/simulations",
    routePaths: ["/", "/simulations"],
    component: Simulations,
    icon: <FontAwesomeIcon icon={faFireAlt} size="2x" fixedWidth />,
  } as RouteData,
  {
    name: "Devices",
    linkPath: "/devices",
    routePaths: ["/devices"],
    component: Devices,
    icon: <FontAwesomeIcon icon={faVrCardboard} size="2x" fixedWidth />,
  } as RouteData,
  {
    name: "Web Passwords",
    linkPath: "/web-passwords",
    routePaths: ["/web-passwords"],
    component: WebPasswords,
    icon: <FontAwesomeIcon icon={faLock} size="2x" fixedWidth />,
  } as RouteData,
];

const VERSION_TEXT = `Version ${version}`;

const APP_NAME = "Calgary Fire VR";

const SIMULATIONS_PER_PAGE = 6;

const DEVICES_PER_PAGE = 8;

const WEB_PASSWORDS_PER_PAGE = 16;

const WEB_SERVICE_URL = process.env.REACT_APP_WEB_SERVICE_URL || `${window.location.origin}/api`;

const INITIAL_SECTION = {
  ACTIVE_SIMULATIONS: "Display the active simulations",
  DELETED_SIMULATIONS: "Display the deleted simulations",
};

const MESSAGES = {
  SIMULATION: {
    CREATE_SUCCESS: "Simulation created successfully!",
    CREATE_ERROR: "Unable to create the simulation!",
    CREATE_ERROR_AUTH: "Only administrators can create a simulation!",
    UPDATE_SUCCESS: "Simulation updated successfully!",
    UPDATE_ERROR: "Unable to update the simulation!",
    UPDATE_ERROR_AUTH: "Only administrators can update a simulation!",
    DELETE_SUCCESS: "Simulation deleted successfully!",
    DELETE_ERROR: "Unable to delete the simulation!",
    DELETE_ERROR_AUTH: "Only administrators can delete a simulation!",
    RESTORE_SUCCESS: "Simulation restored successfully!",
    RESTORE_ERROR: "Unable to restore the simulation!",
    RESTORE_ERROR_AUTH: "Only administrators can restore a simulation!",
    DOWNLOAD_DATA_SUCCESS: "Simulation's survey data downloaded successfully!",
    DOWNLOAD_DATA_ERROR: "Unable to download the simulation's survey data!",
    DOWNLOAD_DATA_ERROR_AUTH: "Only administrators can download the simulation's survey data!",
    FETCH_ERROR: "Unable to get the simulations!",
    FETCH_ERROR_AUTH: "Only administrators can see the simulations!",
    FETCH_DELETED_ERROR: "Unable to get the deleted simulations!",
    FETCH_DELETED_ERROR_AUTH: "Only administrators can see the deleted simulations!",
    IMAGE_UPLOAD_SUCCESS: "Simulation image uploaded successfully!",
    IMAGE_UPLOAD_ERROR: "Unable to upload an image for the simulation!",
    IMAGE_UPLOAD_ERROR_AUTH: "Only administrators can upload an image for the simulation!",
    VR_UPLOAD_SUCCESS: "Simulation VR file uploaded successfully!",
    VR_UPLOAD_ERROR: "Unable to upload the simulation's VR file!",
    VR_UPLOAD_ERROR_AUTH: "Only administrators can upload the simulation's VR file!",
    WEB_UPLOAD_SUCCESS: "Simulation Web file uploaded successfully!",
    WEB_UPLOAD_ERROR: "Unable to upload the simulation's Web file!",
    WEB_UPLOAD_ERROR_AUTH: "Only administrators can upload the simulation's Web file!",
  },
  DEVICE: {
    SIMULATION_FETCH_ERROR: "Unable to get the other simulations!",
    SIMULATION_FETCH_ERROR_AUTH: "Only administrators can have the other simulations displayed!",
    ADD_TO_LIBRARY_SUCCESS: "Simulation successfully added to this device's library!",
    ADD_TO_LIBRARY_ERROR: "Unable to add the simulation to this device's library!",
    ADD_TO_LIBRARY_ERROR_AUTH: "Only administrators can add a simulation to a device's library!",
    REMOVE_FROM_LIBRARY_SUCCESS: "Simulation successfully removed from this device's library!",
    REMOVE_FROM_LIBRARY_ERROR: "Unable to remove the simulation from this device's library!",
    REMOVE_FROM_LIBRARY_ERROR_AUTH:
      "Only administrators can remove a simulation from a device's library!",
    DELETE_SUCCESS: "Device deleted successfully!",
    DELETE_ERROR: "Unable to delete the device!",
    DELETE_ERROR_AUTH: "Only administrators can delete a device!",
    FETCH_ERROR: "Unable to get the devices!",
    FETCH_ERROR_AUTH: "Only administrators can see the devices!",
  },
  WEB_PASSWORD: {
    CREATE_SUCCESS: "Web password created successfully!",
    CREATE_ERROR: "Unable to create the web password!",
    CREATE_ERROR_AUTH: "Only administrators can create a web password!",
    UPDATE_SUCCESS: "Web password updated successfully!",
    UPDATE_ERROR: "Unable to update the web password!",
    UPDATE_ERROR_AUTH: "Only administrators can update a web password!",
    DELETE_SUCCESS: "Web password deleted successfully!",
    DELETE_ERROR: "Unable to delete the web password!",
    DELETE_ERROR_AUTH: "Only administrators can delete a web password!",
    FETCH_ERROR: "Unable to get the web passwords!",
    FETCH_ERROR_AUTH: "Only administrators can see the web passwords!",
    COPY_TO_CLIPBOARD: "Copied to clipboard!",
  },
};

const DEVICE = {
  PAGE_TITLE: "Devices",
  MODAL: {
    DELETE: {
      TITLE: "Delete Device",
      CANCEL: "Cancel",
      CONFIRM: "Delete",
      CONTENT: "Are you really sure you want to delete this device?",
    },
    SIMULATION_LINK: {
      CONFIRM: "OK",
      LEFT_HEADER: "Device Library",
      LEFT_HEADER_TOOLTIP:
        "Modifications made to the device library will not be applied on the physical device until you run the launcher with internet connection.",
      LEFT_EMPTY_LIST_PLACEHOLDER: "No simulations found on the device library",
      RIGHT_HEADER: "Other Simulations",
      RIGHT_HEADER_TOOLTIP:
        "These are simulations applicable to this device, but are currently not part of the device library.",
      RIGHT_EMPTY_LIST_PLACEHOLDER: "No other simulations found",
    },
    NOTIFICATION: {
      TITLE: "Reminder",
      CONFIRM: "OK",
      CONTENT:
        "Modifications made to the device library will not be applied on the physical device until you run the launcher with internet connection.",
    },
  },
  CARD: {
    OPTIONS: {
      MANAGE: "Manage",
      DELETE: "Delete",
    },
  },
  EMPTY_LIST_PLACEHOLDER: "No devices found",
};

const SIMULATION = {
  PAGE_TITLE: "Simulations",
  MODAL: {
    ADD: {
      TITLE: "Add Simulation",
      CANCEL: "Cancel",
      CONFIRM: "Add",
      NAME_LABEL: "Name",
      PACKAGE_NAME_LABEL: "Package Name",
      DESCRIPTION_LABEL: "Description",
    },
    EDIT: {
      TITLE: "Edit Simulation",
      CANCEL: "Cancel",
      CONFIRM: "Save",
      NAME_LABEL: "Name",
      PACKAGE_NAME_LABEL: "Package Name",
      DESCRIPTION_LABEL: "Description",
    },
    DELETE: {
      TITLE: "Delete Simulation",
      CANCEL: "Cancel",
      CONFIRM: "Delete",
      CONTENT: "Are you really sure you want to delete this simulation?",
    },
    DOWNLOAD: {
      TITLE: "SimulationName",
      CANCEL: "Cancel",
      CONFIRM: "Download",
      NAME_LABEL: "Name",
      DESCRIPTION_LABEL: "Description",
    },
  },
  CARD: {
    IMAGE_UPLOAD_HELPER:
      "It is recommended to upload an image with exactly square dimensions (ex. 256x256, 512x512 pixels). Images in .jpg format is also recommended.",
    OPTIONS: {
      VR: "Upload VR",
      WEB: "Upload Web",
      IMAGE: "Upload Image",
      EDIT: "Edit",
      DELETE: "Delete",
      DOWNLOAD: "Download Data",
      RESTORE: "Restore",
    },
    HEADER: {
      TITLE: {
        VR_CHIP: {
          UPLOADED: "VR simulation has been uploaded",
          NOT_UPLOADED: "VR simulation has not been uploaded",
        },
        WEB_CHIP: {
          UPLOADED: "Web simulation has been uploaded",
          NOT_UPLOADED: "Web simulation has not been uploaded",
        },
      },
      ACTION_BUTTON: {
        ADD: "Add this simulation to the device",
        REMOVE: "Remove this simulation from the device",
      },
    },
  },
  EMPTY_LIST_PLACEHOLDER: "No simulations found",
  FILE_UPLOADING: "File uploading...",
  FILE_EXTRACTING: "File extracting...",
};

const WEB_PASSWORD = {
  PAGE_TITLE: "Web Passwords",
  MODAL: {
    ADD: {
      TITLE: "Add Web Password",
      CANCEL: "Cancel",
      CONFIRM: "Add",
      USER_TYPE_LABEL: "User Type",
      PASSWORD_LABEL: "Password",
    },
    EDIT: {
      TITLE: "Edit Web Password",
      CANCEL: "Cancel",
      CONFIRM: "Save",
      USER_TYPE_LABEL: "User Type",
      PASSWORD_LABEL: "Password",
    },
    DELETE: {
      TITLE: "Delete Web Password",
      CANCEL: "Cancel",
      CONFIRM: "Delete",
      CONTENT: "Are you really sure you want to delete this web password?",
    },
  },
  CARD: {
    OPTIONS: {
      EDIT: "Edit",
      DELETE: "Delete",
    },
  },
  EMPTY_LIST_PLACEHOLDER: "No web passwords found",
};

export {
  ROUTES_DATA,
  VERSION_TEXT,
  APP_NAME,
  SIMULATIONS_PER_PAGE,
  DEVICES_PER_PAGE,
  WEB_PASSWORDS_PER_PAGE,
  WEB_SERVICE_URL,
  MESSAGES,
  DEVICE,
  SIMULATION,
  WEB_PASSWORD,
  INITIAL_SECTION,
};
