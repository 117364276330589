import React, { useEffect, useState, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Modal from "../Modal";
import EmptyListPlaceholder from "../EmptyListPlaceholder";
import SimulationCard from "../SimulationCard";
import PageLoader from "../PageLoader";

import { getImageFromStorage } from "../../gateway/FileManagement";
import {
  Simulation,
  Device,
  SimulationImageLink,
  SimulationImageData,
} from "../../utilities/types";
import { SimulationCardVariants } from "../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import { DEVICE } from "../../constants";

import "./styles.scss";

type Props = {
  confirmText: string;
  open: boolean;
  addedSimulations: Simulation[];
  availableSimulations: Simulation[];
  device: Device | undefined;
  loadingSimulationData: boolean;
  handleDeviceLibraryAdd: (simulationId: string) => Promise<void>;
  handleDeviceLibraryRemove: (simulationId: string) => Promise<void>;
  handleModalClose: () => void;
  handleModalConfirm: () => void;
};

const SimulationLinkModal: React.FunctionComponent<Props> = ({
  confirmText,
  open,
  addedSimulations,
  availableSimulations,
  device,
  loadingSimulationData,
  handleDeviceLibraryAdd,
  handleDeviceLibraryRemove,
  handleModalClose,
  handleModalConfirm,
}) => {
  const [simulationImages, setSimulationImages] = useState<SimulationImageData[]>([]);

  const isMounted = useIsMounted();

  /**
   * Loading the images here, so that we can pass the links directly to the cards.
   */
  const loadImages = useCallback(async (): Promise<void> => {
    const allSimulations: Simulation[] = [...addedSimulations, ...availableSimulations];
    const imagesPromises: Promise<SimulationImageData>[] = allSimulations.map(
      async (simulation: Simulation) => {
        const link: SimulationImageLink = simulation.image
          ? await getImageFromStorage(simulation.image, simulation.id)
          : null;
        const data: SimulationImageData = { id: simulation.id, link };
        return data;
      }
    );
    const results: PromiseSettledResult<SimulationImageData>[] = await Promise.allSettled(
      imagesPromises
    );
    const imagesData = results.map((result: PromiseSettledResult<SimulationImageData>) => ({
      ...(result as PromiseFulfilledResult<SimulationImageData>).value,
    }));
    if (isMounted()) setSimulationImages(imagesData);
  }, [addedSimulations, availableSimulations, isMounted]);

  /**
   * Load images when modal gets opened, and there are simulations that should be displayed on that modal.
   */
  useEffect(() => {
    const modalHasSimulations: boolean =
      addedSimulations.length > 0 || availableSimulations.length > 0;
    if (!modalHasSimulations || !open) return;
    loadImages();
  }, [loadImages, addedSimulations, availableSimulations, open]);

  const EmptyList = (text: string): React.ReactNode => <EmptyListPlaceholder text={text} />;

  const SimulationCards = (
    simulations: Simulation[],
    variant: SimulationCardVariants
  ): React.ReactNode =>
    simulations.map((simulation: Simulation, index: number) => (
      <Grid key={simulation.name} item md={12}>
        <SimulationCard
          index={index}
          simulation={simulation}
          variant={variant}
          image={
            simulationImages.find(
              (imageData: SimulationImageData) => imageData.id === simulation.id
            )?.link
          }
          isSimulationDeleted={false}
          handleDeviceLibraryAdd={() => handleDeviceLibraryAdd(simulation.id)}
          handleDeviceLibraryRemove={() => handleDeviceLibraryRemove(simulation.id)}
        />
      </Grid>
    ));

  const SimulationCardList = (
    simulationsList: Simulation[],
    variant: SimulationCardVariants,
    emptyText: string
  ) => (
    <Grid
      container
      spacing={2}
      className="simulationCardList customScrollbar"
      data-testid="simulationCardList"
    >
      {simulationsList.length < 1
        ? EmptyList(emptyText)
        : SimulationCards(simulationsList, variant)}
    </Grid>
  );

  const Loading = (): React.ReactNode => <PageLoader />;

  return (
    <Modal
      title={device?.name}
      confirmText={confirmText}
      open={open}
      danger={false}
      big
      hideCancel
      handleModalClose={handleModalClose}
      handleModalConfirm={handleModalConfirm}
    >
      <div className="SimulationLinkModal">
        <div className="simulationsContainer">
          <div className="leftContainer" data-testid="leftContainer">
            <div className="leftContainerHeader">
              <Typography component="div" variant="h5" className="text">
                {DEVICE.MODAL.SIMULATION_LINK.LEFT_HEADER}
              </Typography>
              <Tooltip
                classes={{ tooltip: "customTooltipDisplay" }}
                title={DEVICE.MODAL.SIMULATION_LINK.LEFT_HEADER_TOOLTIP}
                placement="right-start"
              >
                <IconButton className="infoIcon">
                  <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                </IconButton>
              </Tooltip>
            </div>
            {loadingSimulationData
              ? Loading()
              : SimulationCardList(
                  addedSimulations,
                  SimulationCardVariants.Remove,
                  DEVICE.MODAL.SIMULATION_LINK.LEFT_EMPTY_LIST_PLACEHOLDER
                )}
          </div>
          <div className="dividerContainer" data-testid="dividerContainer">
            <Divider orientation="vertical" className="divider" />
          </div>
          <div className="rightContainer" data-testid="rightContainer">
            <div className="rightContainerHeader">
              <Typography component="div" variant="h5" className="text">
                {DEVICE.MODAL.SIMULATION_LINK.RIGHT_HEADER}
              </Typography>
              <Tooltip
                classes={{ tooltip: "customTooltipDisplay" }}
                title={DEVICE.MODAL.SIMULATION_LINK.RIGHT_HEADER_TOOLTIP}
                placement="right-start"
              >
                <IconButton className="infoIcon">
                  <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                </IconButton>
              </Tooltip>
            </div>
            {loadingSimulationData
              ? Loading()
              : SimulationCardList(
                  availableSimulations,
                  SimulationCardVariants.Add,
                  DEVICE.MODAL.SIMULATION_LINK.RIGHT_EMPTY_LIST_PLACEHOLDER
                )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SimulationLinkModal;
