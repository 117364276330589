import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { RedirectRequest, SilentRequest } from "@azure/msal-browser";
import axios, { AxiosRequestConfig } from "axios";

type Props = {
  children: React.ReactNode;
};

const RequestInterceptor: React.FunctionComponent<Props> = ({ children }) => {
  const { instance, accounts } = useMsal();

  /**
   * Getting the logged account value, to use on the onRequest method.
   * useAccount returns the AccountInfo object for that account if it is signed in or null if it is not.
   */
  const account = useAccount(accounts[0]);

  /**
   * onRequest method added as an interceptor on the axios request, executing everytime a request is sent.
   */
  const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (!account) throw Error("No active account!");

    const scopes: string[] = [`${instance.getConfiguration().auth.clientId}/.default`];
    const redirectRequest: RedirectRequest = { scopes };
    const silentRequest: SilentRequest = { ...redirectRequest, account };
    const response = await instance.acquireTokenSilent(silentRequest);

    const bearer = `Bearer ${response.accessToken}`;
    // @ts-ignore
    config.headers.Authorization = bearer;

    return config;
  };

  /**
   * Here we set the function intercept the requests.
   */
  axios.interceptors.request.use(onRequest);

  return <>{children}</>;
};

export default RequestInterceptor;
