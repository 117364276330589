/**
 * Function that localizes a given date. It uses the getTimezoneOffset method.
 * @param {string} date The date to be localized.
 * @returns {Date} The localized date.
 */
const localizeDate = (date: string): Date => {
  const localDate = new Date(date);
  localDate.setTime(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
  return localDate;
};

export { localizeDate };
