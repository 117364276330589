import React, { useEffect, useState, useCallback } from "react";

import Container from "@mui/material/Container";
import SimulationCardList from "../../components/SimulationCardList";
import PaginationSection from "../../components/PaginationSection";
import FloatingActionButton from "../../components/FloatingActionButton";
import InitialSection from "../../components/InitialSection";
import SimulationAddEditModal from "../../components/SimulationAddEditModal";
import PageLoader from "../../components/PageLoader";

import { getAllSimulations, insertSimulation } from "../../gateway/Simulation";
import { getImageFromStorage } from "../../gateway/FileManagement";
import {
  Platform,
  Simulation,
  SimulationInsertData,
  SimulationUpdateData,
  SimulationImageLink,
  SimulationImageData,
  SimulationGetParams,
} from "./../../utilities/types";
import { MessageVariants, SimulationDisplay } from "./../../utilities/enums";
import useIsMounted from "./../../utilities/useIsMounted";
import useMessage from "./../../utilities/useMessage";
import { checkMessageText } from "./../../utilities/messageHelper";
import { handleInputOnChange } from "./../../utilities/inputHelper";
import { getPagesCount, itemsToRender, filterItemsPerName } from "./../../utilities/pagination";
import { MESSAGES, SIMULATION } from "./../../constants";

const Simulations: React.FunctionComponent = () => {
  const [simulations, setSimulations] = useState<Simulation[]>([]);
  const [simulationImages, setSimulationImages] = useState<SimulationImageData[]>([]);
  const [deletedSimulations, setDeletedSimulations] = useState<Simulation[]>([]);
  const [deletedSimulationImages, setDeletedSimulationImages] = useState<SimulationImageData[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [addSimulationName, setAddSimulationName] = useState<string>("");
  const [addSimulationPackageName, setAddSimulationPackageName] = useState<string>("");
  const [addSimulationDescription, setAddSimulationDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDeleted, setLoadingDeleted] = useState<boolean>(true);
  const [mode, setMode] = useState<SimulationDisplay>(SimulationDisplay.NonDeleted);

  const isMounted = useIsMounted();
  const message = useMessage();

  /**
   * Simulations are fetched and ordered by the creationDate field (newest first)
   */
  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const allSimulations: Simulation[] = await getAllSimulations();
      allSimulations.sort(
        (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );
      if (isMounted()) setSimulations(allSimulations);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.FETCH_ERROR,
        MESSAGES.SIMULATION.FETCH_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
    if (isMounted()) setLoading(false);
  }, [isMounted, message]);

  /**
   * Deleted simulations are also fetched and ordered by the creationDate field (newest first)
   */
  const fetchDataDeleted = useCallback(async (): Promise<void> => {
    setLoadingDeleted(true);
    try {
      const params: SimulationGetParams = { isDeleted: true };
      const allDeletedSimulations: Simulation[] = await getAllSimulations(params);
      allDeletedSimulations.sort(
        (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );
      if (isMounted()) setDeletedSimulations(allDeletedSimulations);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.FETCH_DELETED_ERROR,
        MESSAGES.SIMULATION.FETCH_DELETED_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
    if (isMounted()) setLoadingDeleted(false);
  }, [isMounted, message]);

  useEffect(() => {
    fetchData();
    fetchDataDeleted();
  }, [fetchData, fetchDataDeleted]);

  /**
   * Loading the simulations' images here, so that we can pass the links directly to the cards.
   */
  const loadImages = useCallback(async (): Promise<void> => {
    const imagesPromises: Promise<SimulationImageData>[] = simulations.map(
      async (simulation: Simulation) => {
        const link: SimulationImageLink = simulation.image
          ? await getImageFromStorage(simulation.image, simulation.id)
          : null;
        const data: SimulationImageData = { id: simulation.id, link };
        return data;
      }
    );
    const results: PromiseSettledResult<SimulationImageData>[] = await Promise.allSettled(
      imagesPromises
    );
    const imagesData = results.map((result: PromiseSettledResult<SimulationImageData>) => ({
      ...(result as PromiseFulfilledResult<SimulationImageData>).value,
    }));
    if (isMounted()) setSimulationImages(imagesData);
  }, [simulations, isMounted]);

  /**
   * Loading the deleted simulations' images here, so that we can pass the links directly to the cards.
   */
  const loadImagesDeleted = useCallback(async (): Promise<void> => {
    const imagesPromises: Promise<SimulationImageData>[] = deletedSimulations.map(
      async (simulation: Simulation) => {
        const link: SimulationImageLink = simulation.image
          ? await getImageFromStorage(simulation.image, simulation.id)
          : null;
        const data: SimulationImageData = { id: simulation.id, link };
        return data;
      }
    );
    const results: PromiseSettledResult<SimulationImageData>[] = await Promise.allSettled(
      imagesPromises
    );
    const imagesData = results.map((result: PromiseSettledResult<SimulationImageData>) => ({
      ...(result as PromiseFulfilledResult<SimulationImageData>).value,
    }));
    if (isMounted()) setDeletedSimulationImages(imagesData);
  }, [deletedSimulations, isMounted]);

  useEffect(() => {
    loadImages();
    loadImagesDeleted();
  }, [loadImages, loadImagesDeleted, deletedSimulations]);

  const filteredSimulations: Simulation[] = !filter
    ? simulations
    : (filterItemsPerName(simulations, filter) as Simulation[]);
  const filteredDeletedSimulations: Simulation[] = !filter
    ? deletedSimulations
    : (filterItemsPerName(deletedSimulations, filter) as Simulation[]);

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleAddModalOpen = (): void => {
    setModalOpen(true);
  };

  const handleAddModalClose = (): void => {
    setModalOpen(false);
    clearAddModalValues();
  };

  const clearAddModalValues = (): void => {
    setAddSimulationName("");
    setAddSimulationPackageName("");
    setAddSimulationDescription("");
  };

  const handleAddModalConfirm = async (): Promise<void> => {
    try {
      const insertData: SimulationInsertData = {
        name: addSimulationName,
        packageName: addSimulationPackageName,
        description: addSimulationDescription,
      };
      handleAddModalClose();
      const newSimulation: Simulation = await insertSimulation(insertData);
      handleSpecificInsertion(newSimulation);
      message(MESSAGES.SIMULATION.CREATE_SUCCESS, MessageVariants.Success);
    } catch (error: unknown) {
      const messageText = checkMessageText(
        error as Error,
        MESSAGES.SIMULATION.CREATE_ERROR,
        MESSAGES.SIMULATION.CREATE_ERROR_AUTH
      );
      message(messageText, MessageVariants.Error);
    }
  };

  const handleSpecificInsertion = useCallback(
    (newSimulation: Simulation): void => {
      const newSimulations: Simulation[] = [...simulations];
      newSimulations.unshift(newSimulation);
      if (isMounted()) setSimulations(newSimulations);
    },
    [simulations, isMounted]
  );

  const handleSpecificRestore = useCallback(
    (restoredSimulation: Simulation): void => {
      const newSimulations: Simulation[] = [...simulations];
      newSimulations.unshift(restoredSimulation);
      newSimulations.sort(
        (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );
      if (isMounted()) setSimulations(newSimulations);
      const newDeletedSimulations = deletedSimulations.filter(
        (simulation: Simulation) => simulation.id !== restoredSimulation.id
      );
      if (isMounted()) setDeletedSimulations(newDeletedSimulations);
    },
    [simulations, deletedSimulations, isMounted]
  );

  const handleSpecificUpdate = useCallback(
    (id: string, simulationData: SimulationUpdateData | SimulationInsertData): void => {
      const newSimulations: Simulation[] = [...simulations];
      const index: number = simulations.findIndex((simulation: Simulation) => simulation.id === id);
      newSimulations[index] = { ...newSimulations[index], ...simulationData };
      if (isMounted()) setSimulations(newSimulations);
    },
    [simulations, isMounted]
  );

  /**
   * After successfully uploading a platform file for a simulation, should update that simulation's platforms
   * by removing the existing register for the current uploaded platform type, and inserting the new value (on the state variable).
   */
  const handleSpecificUpdatePlatform = useCallback(
    (id: string, newPlatform: Platform): void => {
      const newSimulations: Simulation[] = [...simulations];
      const index: number = simulations.findIndex((simulation: Simulation) => simulation.id === id);
      const currentPlatforms: Platform[] = newSimulations[index].platforms;
      const shouldKeep: Platform[] = currentPlatforms.filter(
        (platform: Platform) => platform.type !== newPlatform.type
      );
      newSimulations[index].platforms = [...shouldKeep, newPlatform];
      if (isMounted()) setSimulations(newSimulations);
    },
    [simulations, isMounted]
  );

  const handleSpecificDelete = useCallback(
    (deletedSimulation: Simulation): void => {
      const newSimulations = simulations.filter(
        (simulation: Simulation) => simulation.id !== deletedSimulation.id
      );
      if (isMounted()) setSimulations(newSimulations);
      const newDeletedSimulations: Simulation[] = [...deletedSimulations];
      newDeletedSimulations.unshift(deletedSimulation);
      newDeletedSimulations.sort(
        (a: Simulation, b: Simulation) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
      );
      if (isMounted()) setDeletedSimulations(newDeletedSimulations);
    },
    [simulations, deletedSimulations, isMounted]
  );

  const Loading = (): React.ReactNode => <PageLoader />;

  const CardList = (
    simulationsToRender: Simulation[],
    images: SimulationImageData[],
    isSimulationDeleted: boolean
  ): React.ReactNode => (
    <SimulationCardList
      simulations={itemsToRender(simulationsToRender, page) as Simulation[]}
      allSimulationsImageData={images}
      handleSpecificUpdate={handleSpecificUpdate}
      handleSpecificUpdatePlatform={handleSpecificUpdatePlatform}
      handleSpecificDelete={handleSpecificDelete}
      handleSpecificRestore={handleSpecificRestore}
      isSimulationDeleted={isSimulationDeleted}
    />
  );

  const NonDeletedDisplay = (): React.ReactNode =>
    loading ? Loading() : CardList(filteredSimulations, simulationImages, false);

  const DeletedDisplay = (): React.ReactNode =>
    loadingDeleted
      ? Loading()
      : CardList(filteredDeletedSimulations, deletedSimulationImages, true);

  const handleOnNonDeletedModeClick = (): void => {
    setPage(1);
    setMode(SimulationDisplay.NonDeleted);
  };

  const handleOnDeletedModeClick = (): void => {
    setPage(1);
    setMode(SimulationDisplay.Deleted);
  };

  return (
    <div data-testid="simulationsContent">
      <Container maxWidth="xl" disableGutters>
        <InitialSection
          pageTitle={SIMULATION.PAGE_TITLE}
          displayFilter
          onFilterChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputOnChange(event, setFilter)
          }
          displayModeSelector
          onNonDeletedModeClick={handleOnNonDeletedModeClick}
          onDeletedModeClick={handleOnDeletedModeClick}
          currentMode={mode}
        />
        {mode === SimulationDisplay.NonDeleted && NonDeletedDisplay()}
        {mode === SimulationDisplay.Deleted && DeletedDisplay()}
        <PaginationSection
          page={page}
          count={getPagesCount(
            mode === SimulationDisplay.NonDeleted ? filteredSimulations : filteredDeletedSimulations
          )}
          onChange={onPaginationChange}
        />
      </Container>
      {mode === SimulationDisplay.NonDeleted && (
        <FloatingActionButton onClick={handleAddModalOpen} />
      )}
      <SimulationAddEditModal
        title={SIMULATION.MODAL.ADD.TITLE}
        cancelText={SIMULATION.MODAL.ADD.CANCEL}
        confirmText={SIMULATION.MODAL.ADD.CONFIRM}
        open={modalOpen}
        nameLabel={SIMULATION.MODAL.ADD.NAME_LABEL}
        nameValue={addSimulationName}
        packageNameLabel={SIMULATION.MODAL.ADD.PACKAGE_NAME_LABEL}
        packageNameValue={addSimulationPackageName}
        descriptionLabel={SIMULATION.MODAL.ADD.DESCRIPTION_LABEL}
        descriptionValue={addSimulationDescription}
        setNameValue={setAddSimulationName}
        setPackageNameValue={setAddSimulationPackageName}
        setDescriptionValue={setAddSimulationDescription}
        handleModalClose={handleAddModalClose}
        handleModalConfirm={handleAddModalConfirm}
      />
    </div>
  );
};

export default Simulations;
